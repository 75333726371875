import React, { useState } from 'react';
import { List, ListItem } from 'material-ui/List';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import { useTranslation } from 'react-i18next';

const validateEmail = (value: string) => {
  // eslint-disable-next-line
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value);
};

interface RegisterFormProps {
  handleToggleRegisterForm: (toggle: boolean) => void;
  handleRegisterRequest: (
    email?: string,
    password?: string,
    passwordConfirmation?: string,
    firstName?: string,
    lastName?: string,
  ) => void;
}

function RegisterForm({ handleToggleRegisterForm, handleRegisterRequest }: RegisterFormProps) {
  const { t } = useTranslation();

  const [valid, setValid] = useState(false);
  const [email, setEmail] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [passwordError, setPasswordError] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [firstNameError, setFirstNameError] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [lastNameError, setLastNameError] = useState<string>();
  const [passwordConfirmation, setPasswordConfirmation] = useState<string>();
  const [passwordConfirmationError, setPasswordConfirmationError] = useState<string>();

  const validate = (immediatelyInvalid: boolean) => {
    if (immediatelyInvalid) {
      setValid(false);
      return;
    }
    if (
      email &&
      password &&
      passwordConfirmation &&
      firstName &&
      lastName &&
      !emailError &&
      !passwordError &&
      !passwordConfirmationError &&
      !firstNameError &&
      !lastNameError
    ) {
      setValid(true);
    } else {
      setValid(false);
    }
  };

  // FIXME: Refactor this ladder
  const handleEmailChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!event.target.value || !validateEmail(event.target.value)) {
      setEmailError(t('register-form.invalid-email'));
      validate(true);
    } else {
      setEmail(event.target.value);
      setEmailError('');
      validate(false);
    }
  };

  // eslint-disable-next-line max-len
  const handlePasswordChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!event.target.value || event.target.value.length < 8) {
      setPasswordError(t('register-form.invalid-password-length'));
      validate(true);
    } else {
      setPassword(event.target.value);
      setPasswordError('');
      validate(false);
    }
  };

  // eslint-disable-next-line max-len
  const handlePasswordConfirmationChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.target.value !== password) {
      setPasswordConfirmationError(t('register-form.passwords-dont-match'));
      validate(true);
    } else {
      setPasswordConfirmation(event.target.value);
      setPasswordConfirmationError('');
      validate(false);
    }
  };

  // eslint-disable-next-line max-len
  const handleFirstNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!event.target.value) {
      setFirstNameError(t('register-form.field-is-required'));
      validate(true);
    } else {
      setFirstName(event.target.value);
      setFirstNameError('');
      validate(false);
    }
  };

  // eslint-disable-next-line max-len
  const handleLastNameChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (!event.target.value) {
      setLastNameError(t('register-form.field-is-required'));
      validate(true);
    } else {
      setLastName(event.target.value);
      setLastNameError('');
      validate(false);
    }
  };

  return (
    <div>
      <List>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="top-rounded">
          <TextField
            hintText={t('register-form.email')}
            type="mixed-content"
            fullWidth
            onChange={handleEmailChange}
            errorText={emailError}
          />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }}>
          <TextField
            hintText={t('register-form.password')}
            fullWidth
            type="password"
            errorText={passwordError}
            onChange={handlePasswordChange}
          />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }}>
          <TextField
            hintText={t('register-form.repeat-password')}
            fullWidth
            type="password"
            errorText={passwordConfirmationError}
            onChange={handlePasswordConfirmationChange}
          />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }}>
          <TextField
            hintText={t('register-form.first-name')}
            fullWidth
            errorText={firstNameError}
            onChange={handleFirstNameChange}
          />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }}>
          <TextField
            hintText={t('register-form.last-name')}
            fullWidth
            errorText={lastNameError}
            onChange={handleLastNameChange}
          />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="bottom-rounded">
          <RaisedButton
            label={t('register-form.create-account')}
            primary
            onMouseUp={() => {
              handleRegisterRequest(email, password, passwordConfirmation, firstName, lastName);
            }}
            disabled={!valid}
          />
          <FlatButton label={t('register-form.cancel')} secondary onMouseUp={() => handleToggleRegisterForm(false)} />
        </ListItem>
      </List>
    </div>
  );
}

export default RegisterForm;
