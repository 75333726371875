import React from 'react';
import { Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { Card, CardText } from 'material-ui';
import SubscriptionPanel from './SubscriptionPanel';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface CheckoutProps {
  paidUntil: string;
  layouts: Layouts;
  hasPaid: boolean;
}

function Checkout({ layouts, paidUntil, hasPaid }: CheckoutProps) {
  return (
    <ResponsiveReactGridLayout
      className="layout"
      layouts={layouts}
      breakpoints={{
        lg: 1200,
        md: 996,
        sm: 768,
        xs: 480,
        xxs: 0,
      }}
      cols={{
        lg: 12,
        md: 10,
        sm: 6,
        xs: 4,
        xxs: 2,
      }}
    >
      <div key="a" style={{ marginTop: '200px' }}>
        <Card>
          <CardText>
            <SubscriptionPanel paidUntil={paidUntil} hasPaid={hasPaid} infoFontSize={16} />
          </CardText>
        </Card>
      </div>
    </ResponsiveReactGridLayout>
  );
}

export default Checkout;
