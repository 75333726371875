import React, { useState } from 'react';
import FontIcon from 'material-ui/FontIcon';
import { Toolbar, ToolbarGroup } from 'material-ui/Toolbar';
import AutoComplete from 'material-ui/AutoComplete';

import { useTranslation } from 'react-i18next';
import { PointOfInterest } from '../betterTypes';

const styles = {
  toolbar: {
    position: 'absolute',
    top: '5px',
    left: 10,
    right: '5px',
    paddingLeft: 5,
    paddingRight: 5,
    maxWidth: '375px',
    backgroundColor: '#fff',
    boxShadow: '0 10px 6px -6px #777',
  } as React.CSSProperties,
  toolbarGroup: {
    opacity: 1,
    fontFamily: 'Roboto',
    fontSize: 10,
    width: '100%',
    marginLeft: 0,
    paddingLeft: 5,
    paddingRight: 5,
  },
};

interface ToolBarProps {
  pointOfInterests: Array<PointOfInterest>;
  search: (any) => void;
}

const buildDataSource = (pointOfInterests: Array<PointOfInterest>) =>
  pointOfInterests.map((poi) => ({
    text: poi.point_of_interest.name,
    value: {
      properties: {
        id: poi.point_of_interest.id,
        lat: poi.point_of_interest.lat,
        lon: poi.point_of_interest.lon,
        title: poi.point_of_interest.name,
      },
    },
  }));

function ToolBar({ search, pointOfInterests }: ToolBarProps) {
  const { t } = useTranslation();
  const [title] = useState();

  const dataSource = buildDataSource(pointOfInterests).map((name) =>
    name.text && name.text.length > 30 ? `${name.text.substring(0, 30)}...` : name.text,
  );

  return (
    <Toolbar noGutter style={styles.toolbar}>
      <ToolbarGroup firstChild style={styles.toolbarGroup}>
        <AutoComplete
          type="mixed-content"
          hintText={t('search.search')}
          searchText={title}
          fullWidth
          onNewRequest={(_name, index) => search(buildDataSource(pointOfInterests)[index].value)}
          dataSource={dataSource}
          filter={(searchText, key) => searchText !== '' && key.toLowerCase().indexOf(searchText.toLowerCase()) !== -1}
        />
        <FontIcon className="material-icons">search</FontIcon>
      </ToolbarGroup>
    </Toolbar>
  );
}

export default ToolBar;
