import React from 'react';
import { RefreshIndicator } from 'material-ui';

interface FlashScreenProps {
  width: number;
  height: number;
}

function FlashScreen({ width, height }: FlashScreenProps) {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: '#ffffff',
        height,
        width,
        opacity: 1,
        zIndex: 999999999999,
      }}
      className="flash elementToFadeInAndOut fadeOut"
    >
      <RefreshIndicator
        top={0}
        left={0}
        size={50}
        loadingColor="#FF9800"
        status="loading"
        style={{
          position: 'absolute',
          display: 'inline-block',
          top: height / 2 - 25,
          left: width / 2 - 25,
        }}
      />
    </div>
  );
}

export default FlashScreen;
