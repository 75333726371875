const largeLayout = [
  {
    i: 'a',
    x: 4,
    y: 0,
    w: 4,
    h: 2,
    static: true,
  },
];

const mediumLayout = [
  {
    i: 'a',
    x: 3,
    y: 0,
    w: 4,
    h: 2,
    static: true,
  },
];

const smallLayout = [
  {
    i: 'a',
    x: 1,
    y: 0,
    w: 4,
    h: 2,
    static: true,
  },
];

const layouts = { lg: largeLayout, md: mediumLayout, sm: smallLayout };

export default layouts;
