import React, { useState } from 'react';
import { List, ListItem } from 'material-ui/List';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import { useTranslation } from 'react-i18next';

interface ForgotPasswordFormProps {
  handlePasswordChangeRequest: (email: string) => void;
  handleTogglePasswordChangeForm: (toggle: false) => void;
  isLoading: boolean;
}

function ForgotPasswordForm({
  handlePasswordChangeRequest,
  handleTogglePasswordChangeForm,
  isLoading,
}: ForgotPasswordFormProps) {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  return (
    <div>
      <List>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="top-rounded">
          <p>{t('forgot.description')}</p>
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="top-rounded">
          <TextField
            hintText={t('forgot.email')}
            type="mixed-content"
            fullWidth
            value={email}
            onChange={(e) => setEmail((e.target as HTMLInputElement).value)}
          />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="bottom-rounded">
          <RaisedButton
            label={t('forgot.request-password-change')}
            primary
            onMouseUp={() => handlePasswordChangeRequest(email)}
            disabled={isLoading}
          />
          <FlatButton label={t('forgot.cancel')} secondary onMouseUp={() => handleTogglePasswordChangeForm(false)} />
        </ListItem>
      </List>
    </div>
  );
}

export default ForgotPasswordForm;
