import React from 'react';

import useWindowsSize from '@rehooks/window-size';

import { connect } from 'react-redux';
import {
  toggleAccount,
  toggleRoute,
  fetchUserInfo,
  fetchStoredRoutes,
  clearRoute,
  routeSavedReceived,
} from '../actions';

import FloatingMenu from '../components/FloatingMenu';

import { hasPaid } from '../utils/userInfoUtils';
import { Route, User } from '../betterTypes';

interface FloatingMenuContainerProps {
  route: Route;
  user: User;
  userInfo: string;
  toggleAccount: (toggle: boolean) => void;
  toggleRoute: (toggle: boolean) => void;
  clearRoute: (route: Route) => void;
  routeSavedReceived: (route: Route) => void;
  fetchUserInfo: (user: User) => void;
  fetchStoredRoutes: (user: User) => void;
}

function FloatingMenuContainer({
  route,
  user,
  userInfo,
  toggleAccount,
  toggleRoute,
  clearRoute,
  routeSavedReceived,
  fetchUserInfo,
  fetchStoredRoutes,
}: FloatingMenuContainerProps) {
  const { innerWidth, innerHeight } = useWindowsSize();

  const handleUserInfoRequest = (user: User) => {
    fetchUserInfo(user);
    fetchStoredRoutes(user);
  };

  const handleAccountTap = (show: boolean) => {
    toggleAccount(show);
    toggleRoute(false);
    if (show) {
      handleUserInfoRequest(user);
    }
  };

  const handleRouteTap = (show) => {
    toggleRoute(show);
    if (show) {
      handleUserInfoRequest(user);
    }
  };

  const handleClearRoute = () => {
    clearRoute(route);
    routeSavedReceived({ id: 1, name: 'asdf', length: 100, user_id: 1, created_at: '' });
  };

  return (
    <FloatingMenu
      width={innerWidth}
      height={innerHeight}
      hasPaid={hasPaid(userInfo)}
      route={route}
      handleAccountTap={handleAccountTap}
      handleRouteTap={handleRouteTap}
      handleClearRoute={handleClearRoute}
    />
  );
}

export default connect(
  ({ routes: { route }, user }: { routes: FloatingMenuContainerProps; user: { user: User; userInfo: string } }) => ({
    route,
    user: user.user,
    userInfo: user.userInfo,
  }),
  {
    toggleAccount,
    toggleRoute,
    clearRoute,
    routeSavedReceived,
    fetchUserInfo,
    fetchStoredRoutes,
  },
)(FloatingMenuContainer);
