import React from 'react';

import Snackbar from 'material-ui/Snackbar';

const styles = {
  snackbar: {
    fontFamily: 'Roboto',
    fontSize: '8px',
    width: '100%',
    paddingLeft: '200px',
  },
};

interface SnackbarProps {
  showSnackbar: boolean;
  snackbarMessage: string;
  handleRequestClose: (reason: string) => void;
}

function SnackbarComponent({ showSnackbar, snackbarMessage, handleRequestClose }: SnackbarProps) {
  return (
    <div>
      <Snackbar
        bodyStyle={{ height: '100%' }}
        style={styles.snackbar}
        open={showSnackbar}
        message={snackbarMessage}
        autoHideDuration={10000}
        onRequestClose={handleRequestClose}
      />
    </div>
  );
}

export default SnackbarComponent;
