import _ from 'lodash';
import i18n from 'i18next';

require('es6-promise').polyfill();
require('isomorphic-fetch');

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const ROUTE_ENDPOINT = process.env.REACT_APP_ROUTE_ENDPOINT;

const fixLatitude = (wp) => {
  if (!wp.lat && wp.latitude) return wp.latitude;
  if (!wp.lat && !wp.latitude) return wp[1];
  return wp.lat;
};

const fixLongitude = (wp) => {
  if (!wp.lon && wp.longitude) return wp.longitude;
  if (!wp.lon && !wp.longitude) return wp[0];
  return wp.lon;
};

function fixWaypoints(waypoints) {
  return waypoints.map((wp) => ({
    lat: fixLatitude(wp),
    lon: fixLongitude(wp),
  }));
}

export const setSpinner = (show) => ({
  type: 'SET_SPINNER',
  show,
});

export function clearRoute(route) {
  return {
    type: 'CLEAR_ROUTE',
    route,
  };
}

export function hideFlashScreen() {
  return {
    type: 'HIDE_FLASH_SCREEN',
  };
}

export function clearWaypoints(waypoints) {
  return {
    type: 'CLEAR_WAYPOINTS',
    waypoints,
  };
}

export function locationReceived(position) {
  return {
    type: 'LOCATION_RECEIVED',
    position,
  };
}

export function waypointsReceived(waypoints) {
  return {
    type: 'WAYPOINTS_RECEIVED',
    waypoints: fixWaypoints(waypoints),
  };
}

export function routeErrorReceived(error) {
  return {
    type: 'ROUTE_ERROR_RECEIVED',
    error,
  };
}

export function poisReceived(pois) {
  return {
    type: 'POIS_RECEIVED',
    pois,
  };
}

export function loginReceived(user) {
  return {
    type: 'LOGIN_RECEIVED',
    user,
  };
}

export function userInfoReceived(user) {
  return {
    type: 'USER_INFO_RECEIVED',
    user,
  };
}

export const logout = () => ({
  type: 'LOGOUT',
});

export function storedRoutesReceived(routes) {
  return {
    type: 'STORED_ROUTES_RECEIVED',
    routes,
  };
}

export function routeSavedReceived(route) {
  return {
    type: 'ROUTE_SAVED_RECEIVED',
    route,
  };
}

export function showPopup(poi) {
  return {
    type: 'SHOW_POPUP',
    poi,
  };
}

export function toggleRoute(show) {
  return {
    type: 'TOGGLE_ROUTE',
    show,
  };
}

export function toggleAutomaticRoute(use) {
  return {
    type: 'TOGGLE_AUTOMATIC_ROUTE',
    use,
  };
}

export function toggleAccount(show) {
  return {
    type: 'TOGGLE_ACCOUNT',
    show,
  };
}

export function toggleLogin(show) {
  return {
    type: 'TOGGLE_LOGIN',
    show,
  };
}

export function toggleRegister(show) {
  return {
    type: 'TOGGLE_REGISTER',
    show,
  };
}

export function showMessage(message) {
  return {
    type: 'SHOW_MESSAGE',
    message,
  };
}

export function hideMessage(message) {
  return {
    type: 'HIDE_MESSAGE',
    message,
  };
}

export function addToRoute(poi) {
  return {
    type: 'ADD_TO_ROUTE',
    poi,
  };
}

export function setRoute(route) {
  return {
    type: 'SET_ROUTE',
    route,
  };
}

export function removeFromRoute(poi) {
  return {
    type: 'REMOVE_FROM_ROUTE',
    poi,
  };
}

export function moveForwardOnRoute(index) {
  return {
    type: 'MOVE_FORWARD_ON_ROUTE',
    index,
  };
}

export function moveBackOnRoute(index) {
  return {
    type: 'MOVE_BACK_ON_ROUTE',
    index,
  };
}

export function flyTo(poi) {
  return {
    type: 'FLY_TO',
    poi,
  };
}

export function invalidate() {
  return {
    type: 'INVALIDATE',
    user: {},
    userInfo: '',
  };
}

export function getLocation() {
  return (dispatch) => {
    const { geolocation } = navigator;
    const onPositionResponse = (position) => dispatch(locationReceived(position.coords));
    geolocation.getCurrentPosition(onPositionResponse);
  };
}

export function getPois() {
  return (dispatch) =>
    fetch(`${API_ENDPOINT}/point_of_interests.json`)
      .then((response) => response.json())
      .then((pois) => dispatch(poisReceived(pois)));
}

export function register(email, password, passwordConfirmation, firstName, lastName) {
  const user = {
    user: {
      email,
      password,
      password_confirmation: passwordConfirmation,
      first_name: firstName,
      last_name: lastName,
    },
  };
  return (dispatch) => {
    fetch(`${API_ENDPOINT}/users.json`, {
      method: 'post',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        if (response.status === 422) {
          dispatch(showMessage(i18n.t('messages.register-failed-email-used')));
          return null;
        }
        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.register-failed')));
          return null;
        }
        return response.json();
      })
      .then((fetchedUser) => {
        if (fetchedUser) {
          dispatch(loginReceived(fetchedUser));
        }
      });
  };
}

export function fetchUserInfo(user) {
  return (dispatch) => {
    fetch(`${API_ENDPOINT}/users/${user.id}.json`, {
      method: 'get',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: user.token,
      },
    })
      .then((response) => {
        if (response.status === 401) {
          dispatch(invalidate());
          return null;
        }

        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.user-info-failed')));
          return null;
        }
        return response.json();
      })
      .then((fetchedUser) => {
        if (fetchedUser) {
          dispatch(userInfoReceived(fetchedUser));
        }
      });
  };
}

export function fetchStoredRoutes(user) {
  return (dispatch) => {
    fetch(`${API_ENDPOINT}/users/${user.id}/routes.json`, {
      method: 'get',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: user.token,
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.fetch-stored-routes-failed')));
          return null;
        }
        return response.json();
      })
      .then((routes) => {
        if (routes) {
          dispatch(storedRoutesReceived(routes));
        }
      });
  };
}

export function saveRoute(route, waypoints, name, user) {
  const isLocationEqual = (value, other) => {
    if (!other.properties) return false;
    return value.lat === other.properties.lat && value.lon === other.properties.lon;
  };
  // TODO: curry, curry, pipe, pipe;
  const isLocationEqualAndDirect = (value, other) => {
    if (!other.properties) return false;
    return value.lat === other.properties.lat && value.lon === other.properties.lon && other.properties.directRoute;
  };
  const userPoints = _.intersectionWith(waypoints, route, isLocationEqual);
  const directRoutePoints = _.intersectionWith(waypoints, route, isLocationEqualAndDirect);
  const wps = fixWaypoints(waypoints).map((wp) => ({
    latitude: wp.lat,
    longitude: wp.lon,
    userpoint: _.some(userPoints, wp) || false,
    fairway: !_.some(directRoutePoints, wp) || false,
  }));
  const pois = route.map((feature) => feature.properties.id);
  const payload = {
    name,
    point_of_interests: pois,
    waypoints: wps,
  };
  return (dispatch) => {
    fetch(`${API_ENDPOINT}/users/${user.id}/routes.json`, {
      method: 'post',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: user.token,
      },
      body: JSON.stringify(payload),
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.failed-to-save-route')));
          return null;
        }
        return response.json();
      })
      .then((fetchedRoute) => {
        if (fetchedRoute) {
          dispatch(routeSavedReceived(fetchedRoute));
          dispatch(fetchStoredRoutes(user));
          dispatch(showMessage(i18n.t('messages.route-saved')));
        }
      });
  };
}

const fixWaypoint = (wp) => {
  if (!wp.latitude && wp.point_of_interest) {
    const properties = {
      latitude: wp.point_of_interest.lat,
      longitude: wp.point_of_interest.lon,
    };
    if (!wp.fairway) properties.directRoute = true;
    return properties;
  }
  const waypoint = wp;
  if (!wp.fairway) waypoint.directRoute = true;
  return waypoint;
};

const buildFeature = (wp, directRoute, title, id, type) => {
  const waypoint = fixWaypoint(wp);
  let t = title;
  if (!title) {
    t = `[${waypoint.latitude.toFixed(3)}, ${waypoint.longitude.toFixed(3)}]`;
  }
  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [waypoint.longitude, waypoint.latitude],
    },
    properties: {
      title: t,
      id,
      type: type === 'user' ? 'user' : 'marker',
      'marker-symbol': 'ferry',
      lat: waypoint.latitude,
      lon: waypoint.longitude,
      directRoute,
    },
  };
};

export function deleteRoute(user, route) {
  return (dispatch) => {
    fetch(`${API_ENDPOINT}/users/${user.id}/routes/${route.id}.json`, {
      method: 'delete',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: user.token,
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.save-route-failed')));
          return null;
        }
        return response.json();
      })
      .then((result) => {
        if (result) {
          dispatch(clearRoute(route));
          dispatch(fetchStoredRoutes(user));
        }
      });
  };
}

export function fetchStoredRoute(user, id) {
  return (dispatch) => {
    fetch(`${API_ENDPOINT}/users/${user.id}/routes/${id}.json`, {
      method: 'get',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: user.token,
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.fetch-route-failed')));
          return null;
        }
        return response.json();
      })
      .then((route) => {
        const nullCoordinates = route.waypoints.some((wp) => wp.latitude === null || wp.longitude === null);
        if (nullCoordinates) {
          dispatch(showMessage(i18n.t('messages.invalid-route')));
          return null;
        }
        return route;
      })
      .then((route) => {
        if (route) {
          const userPoints = route.waypoints.filter((wp) => wp.userpoint === true);
          const firstWP = route.waypoints[0];
          const lastWP = route.waypoints[route.waypoints.length - 1];
          userPoints.unshift(firstWP);
          userPoints.push(lastWP);
          if (userPoints.length > 0) {
            dispatch(
              setRoute(
                userPoints.map((wp) =>
                  buildFeature(wp, !wp.fairway, undefined, new Date().getUTCMilliseconds(), 'user'),
                ),
              ),
            );
          } else {
            dispatch(
              setRoute([
                buildFeature(route.waypoints[0], false, undefined, new Date().getUTCMilliseconds(), 'user'),
                buildFeature(
                  route.waypoints[route.waypoints.length - 1],
                  false,
                  undefined,
                  new Date().getUTCMilliseconds(),
                  'user',
                ),
              ]),
            );
          }
          dispatch(routeSavedReceived(route));
          dispatch(waypointsReceived(route.waypoints));
        }
      });
  };
}

export function login(email, password) {
  const user = {
    user: {
      email,
      password,
    },
  };
  return (dispatch) => {
    fetch(`${API_ENDPOINT}/users/sign_in.json`, {
      method: 'post',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.login-failed')));
          dispatch(setSpinner(false));
          return null;
        }
        return response.json();
      })
      .then((fetchedUser) => {
        if (fetchedUser) {
          dispatch(loginReceived(fetchedUser));
          dispatch(fetchUserInfo(fetchedUser));
        }
      });
  };
}

export function loginWithFacebook(accessToken) {
  const user = {
    access_token: accessToken,
  };
  return (dispatch) => {
    fetch(`${API_ENDPOINT}/users/auth/facebook.json`, {
      method: 'post',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(user),
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.login-failed')));
          return null;
        }
        return response.json();
      })
      .then((fetchedUser) => {
        if (fetchedUser) {
          dispatch(loginReceived(fetchedUser));
        }
      });
  };
}

const buildRouteUrl = (startWaypoint, endWaypoint) => {
  const staticParamsForPOITap =
    '&is_starting_from_nearest_point_on_fairway=true&is_ending_to_nearest_point_on_fairway=true';
  const waypointParams =
    `?start_lat=${startWaypoint.coordinates.lat}` +
    `&start_lon=${startWaypoint.coordinates.lon}` +
    `&end_lat=${endWaypoint.coordinates.lat}` +
    `&end_lon=${endWaypoint.coordinates.lon}`;
  return `${ROUTE_ENDPOINT}${waypointParams}${staticParamsForPOITap}`;
};

const fetchRoute = (startWaypoint, endWaypoint) =>
  fetch(
    buildRouteUrl(
      {
        coordinates: { lat: startWaypoint.properties.lat, lon: startWaypoint.properties.lon },
      },
      {
        coordinates: { lat: endWaypoint.properties.lat, lon: endWaypoint.properties.lon },
      },
    ),
  ).catch(() => ({ from: startWaypoint.properties.title, to: endWaypoint.properties.title }));

const generateRouteFetchingRequests = (waypoints) =>
  waypoints
    .map((wp, index) => {
      if (index + 1 < waypoints.length) {
        if (!waypoints[index + 1].properties.directRoute) {
          return fetchRoute(wp, waypoints[index + 1]);
        }
        return new Promise((resolve) =>
          // eslint-disable-next-line no-promise-executor-return
          resolve({
            waypoints: [
              { lat: wp.properties.lat, lon: wp.properties.lon, fairway: false },
              {
                lat: waypoints[index + 1].properties.lat,
                lon: waypoints[index + 1].properties.lon,
                fairway: false,
                userpoint: true,
              },
            ],
          }),
        );
      }
      return null;
    })
    .filter((wp) => wp);

const setCalculatingRoute = (isCalculatingRoute) => ({
  type: 'SET_CALCULATING_ROUTE',
  isCalculatingRoute,
});

const waitForAllFetchingRequestsToBeResolvedAndDispatch = (requests, dispatch) => {
  Promise.all(requests).then((results) => {
    const jsons = results.map((response) => {
      if (!response.status || response.status >= 400) {
        return response;
      }
      return response.json();
    });

    Promise.all(jsons).then((json) => {
      let wps = [];
      json.forEach((waypoints) => {
        if (waypoints.waypoints) {
          wps = wps.concat(waypoints.waypoints);
        } else {
          wps = [];
          const message = i18n.t('messages.route-calculation-failed', { from: waypoints.from, to: waypoints.to });
          dispatch(showMessage(message));
          dispatch(routeErrorReceived({ from: waypoints.from, to: waypoints.to }));
        }
      });
      dispatch(waypointsReceived(wps));
      dispatch(setCalculatingRoute(false));
    });
  });
};

export function getRoute(waypoints) {
  return (dispatch, getState) => {
    dispatch(setCalculatingRoute(true));
    dispatch(clearWaypoints(waypoints));

    const requests = generateRouteFetchingRequests(waypoints);

    return waitForAllFetchingRequestsToBeResolvedAndDispatch(requests, dispatch, getState().localize);
  };
}

export const setSeen = (seen) => ({
  type: 'SET_SEEN',
  seen,
});

export const setInfoPage = (infoPage) => ({
  type: 'SET_INFO_PAGE',
  infoPage,
});

export const setScoutRoutes = (show) => ({
  type: 'SET_SCOUT_ROUTES_ACTIVE',
  show,
});

export const scoutRoutesReceived = (routes) => ({
  type: 'SCOUT_ROUTES_RECEIVED',
  routes,
});

export const toggleScoutRoutes = (show) => (dispatch, getState) => {
  const {
    routes: { scoutRoutes },
    user: { user },
  } = getState();
  dispatch(setScoutRoutes(show));
  if (show && scoutRoutes.length === 0) {
    dispatch(setSpinner(true));
    fetch(`${API_ENDPOINT}/routes.json?scout_routes=true`, {
      method: 'get',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: user.token,
      },
    })
      .then((response) => {
        if (response.status >= 400) {
          dispatch(showMessage(i18n.t('messages.fetch-scout-routes-failed')));
          return null;
        }
        return response.json();
      })
      .then((routes) => {
        if (routes) {
          dispatch(scoutRoutesReceived(routes));
        }
      })
      .finally(() => dispatch(setSpinner(false)));
  }
};

export const setForgotLoading = (isLoading) => ({
  type: 'SET_FORGOT_LOADING',
  isLoading,
});

export const requestPasswordChange = (email) => (dispatch) => {
  dispatch(setForgotLoading(true));
  fetch(`${API_ENDPOINT}/users/password`, {
    method: 'post',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user: { email } }),
  })
    .then((response) => {
      if (response.status >= 400) {
        dispatch(showMessage(i18n.t('forgot.request-password-change-failed')));
        return null;
      }

      return response.json();
    })
    .then(() => {
      dispatch(showMessage(i18n.t('forgot.request-password-change-success')));
    })
    .finally(() => dispatch(setForgotLoading(false)));
};

export const changePassword = (newPassword, resetPasswordToken) => (dispatch) => {
  dispatch(setForgotLoading(true));
  return fetch(`${API_ENDPOINT}/users/password`, {
    method: 'put',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user: {
        password: newPassword,
        reset_password_token: resetPasswordToken,
      },
    }),
  }).then((response) => {
    dispatch(setForgotLoading(false));
    if (response.status >= 400) {
      dispatch(showMessage(i18n.t('forgot.password-change-failed')));
      return false;
    }
    dispatch(showMessage(i18n.t('forgot.password-change-success')));
    return true;
  });
};

const downloadFile = (data, fileName, type = 'text/plain') => {
  // Create an invisible A element
  const a = document.createElement('a');
  a.style.display = 'none';
  document.body.appendChild(a);

  // Set the HREF to a Blob representation of the data to be downloaded
  a.href = window.URL.createObjectURL(new Blob([data], { type }));

  // Use download attribute to set set desired file name
  a.setAttribute('download', fileName);

  // Trigger the download by simulating click
  a.click();

  // Cleanup
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

const mimeType = (format) => {
  const mapping = {
    gpx: 'text/plain',
    raymarine: 'text/plain',
    mapsource: 'text/plain',
    lowranceusr: 'text/plain',
    csv: 'text/csv',
  };

  return mapping[format];
};

export const exportRoute = (routeName, format, routeId) => (dispatch, getState) => {
  const {
    user: { user },
  } = getState();
  return fetch(`${API_ENDPOINT}/users/${user.id}/routes/${routeId}/convert?format=${format}`, {
    method: 'get',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: user.token,
    },
  })
    .then((response) => {
      if (response.status >= 400) {
        dispatch(showMessage(i18n.t('routes.export-failed')));
        return false;
      }

      return response.text().then((data) => ({
        data,
        filename: `${routeName}.${format}`,
        type: mimeType(format),
      }));
    })
    .then(({ filename, data, type }) => downloadFile(data, filename, type));
};
