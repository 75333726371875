import React from 'react';
import { FloatingActionButton, FontIcon } from 'material-ui';

interface AutomaticRouteToggleProps {
  height: number;
  useAutomaticRoute: boolean;
  handleToggleUseAutomaticRoute: (toggle: boolean) => void;
}

function AutomaticRouteToggle({ height, useAutomaticRoute, handleToggleUseAutomaticRoute }: AutomaticRouteToggleProps) {
  return (
    <div>
      {useAutomaticRoute ? (
        <FloatingActionButton
          style={{
            position: 'absolute',
            top: height - 90,
            left: 10,
            zIndex: 9999,
          }}
          onMouseUp={() => handleToggleUseAutomaticRoute(false)}
        >
          <FontIcon className="material-icons">timeline</FontIcon>
        </FloatingActionButton>
      ) : (
        <FloatingActionButton
          style={{
            position: 'absolute',
            top: height - 90,
            left: 10,
            zIndex: 9999,
          }}
          onMouseUp={() => handleToggleUseAutomaticRoute(true)}
        >
          <FontIcon className="material-icons">linear_scale</FontIcon>
        </FloatingActionButton>
      )}
    </div>
  );
}

export default AutomaticRouteToggle;
