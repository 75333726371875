import React from 'react';
import PropTypes from 'prop-types';

import { Responsive, WidthProvider } from 'react-grid-layout';

import baseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changePassword, showMessage } from '../actions';

import ResetPasswordForm from '../components/ResetPasswordForm';
import SnackbarContainer from './SnackbarContainer';

import layouts from '../layouts';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const breakpoints = {
  lg: 1200,
  md: 996,
  sm: 768,
  xs: 480,
  xxs: 0,
};
const cols = {
  lg: 12,
  md: 10,
  sm: 6,
  xs: 4,
  xxs: 2,
};

function ResetPassword({ changePassword, showMessage }) {
  const { t } = useTranslation();

  return (
    <MuiThemeProvider muiTheme={getMuiTheme(baseTheme)}>
      <div>
        <ResponsiveReactGridLayout className="layout" layouts={layouts} breakpoints={breakpoints} cols={cols}>
          <div key="a">
            <div>
              <div>
                <h4 style={{ fontFamily: 'Lato', color: '#d9e0e2' }}>
                  <span style={{ color: '#d9e0e2', marginRight: 20 }} className="icon-sails" />
                  {t('forgot.forgot-password')}
                </h4>
              </div>
              <ResetPasswordForm handleChangePassword={changePassword} handleShowMessage={showMessage} />
            </div>
          </div>
        </ResponsiveReactGridLayout>
        <SnackbarContainer />
      </div>
    </MuiThemeProvider>
  );
}

ResetPassword.propTypes = {
  changePassword: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
};

export default compose(connect(() => ({}), { changePassword, showMessage }))(ResetPassword);
