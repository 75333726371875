import React from 'react';

import { connect } from 'react-redux';

import Spinner from '../components/Spinner';

interface SpinnerContainerProps {
  width: number;
  height: number;
  showSpinner: boolean;
}

function SpinnerContainer({ width, height, showSpinner }: SpinnerContainerProps) {
  if (!showSpinner) return null;

  const handleBackdropClick = (e: React.MouseEvent<HTMLElement>) => e.stopPropagation();

  return <Spinner height={height} width={width} handleBackdropClick={handleBackdropClick} />;
}

export default connect((state: { spinner: SpinnerContainerProps }) => ({
  showSpinner: state.spinner.showSpinner,
}))(SpinnerContainer);
