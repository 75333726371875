/* global mapboxgl */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import center from '@turf/center';
import { point, featureCollection } from '@turf/helpers';
import { withTranslation } from 'react-i18next';

import Types from '../types';

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;

const removeLayer = (layerName, map) => {
  if (map.getLayer(layerName)) {
    map.removeLayer(layerName);
  }
};

const removeLayerAndSource = (layerName, sourceName, map) => {
  removeLayer(layerName, map);

  if (map.getSource(sourceName)) {
    map.removeSource(sourceName);
  }
};

const buildTemporaryFeature = (coordinates) => {
  const tmpFeature = {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates,
    },
    properties: {
      id: coordinates[1].toFixed(4) + coordinates[0].toFixed(4),
      title: `[${coordinates[1].toFixed(3)}, ${coordinates[0].toFixed(3)}]`,
      'marker-symbol': 'harbor',
      lat: coordinates[1],
      lon: coordinates[0],
    },
  };
  return tmpFeature;
};

class Map extends Component {
  resizeMap = _.debounce(() => this.map.resize(), 750);

  constructor(props) {
    super(props);
    this.componentDidMount = this.componentDidMount.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.mapRef = React.createRef();
  }

  componentDidMount() {
    const mapCenter = this.props.center;
    this.props.handleGetPois();
    this.map = new mapboxgl.Map({
      container: this.mapRef.current,
      style: 'mapbox://styles/vkeskinen/ciqr1gb4r004vc8j5bs136rjt',
      center: [mapCenter.lon, mapCenter.lat],
      zoom: 9,
    });

    this.map.dragRotate.disable();
    this.map.touchZoomRotate.disableRotation();

    let timer;
    let screenX;
    let screenY;
    const touchDuration = 600;
    const longTouch = (e) => {
      const features = this.map.getLayer('markers')
        ? this.map.queryRenderedFeatures(e.point, {
            layers: ['markers'],
          })
        : [];
      const wpFeatures = this.map.getLayer('firstWP')
        ? this.map.queryRenderedFeatures(e.point, {
            layers: ['firstWP'],
          })
        : [];
      const poisOnRoute = this.map.getLayer('poisOnRoute')
        ? this.map.queryRenderedFeatures(e.point, {
            layers: ['poisOnRoute'],
          })
        : [];

      const wpFeature = wpFeatures.concat(poisOnRoute);

      if (!this.props.useAutomaticRoute && wpFeature.length === 0) {
        this.props.handleDrawLineRoute(buildTemporaryFeature([e.lngLat.lng, e.lngLat.lat]));
        return;
      }

      if (features.length > 0 && wpFeature.length === 0) {
        this.props.poiTap(features[0]);
      } else {
        this.props.poiTap(wpFeature[0]);
      }

      if (features.length > 0 && wpFeature.length === 0) {
        /* eslint-disable prefer-template, no-use-before-define */
        this.props.handleAddToRoute(features[0]);
        return;
      }

      if (features.length === 0 && wpFeature.length === 0) {
        /* eslint-disable prefer-template, no-use-before-define */
        this.props.handleAddToRoute(buildTemporaryFeature([e.lngLat.lng, e.lngLat.lat]));
        return;
      }

      if (wpFeature.length > 0) {
        this.props.handleRemoveFromRoute(wpFeature[0]);
      }
    };
    const preventDefault = (e) => {
      const event = e;
      e.preventDefault();
      e.stopPropagation();
      event.cancelBubble = true;
      event.returnValue = false;
    };
    this.map.on('mousedown', (e) => {
      preventDefault(e.originalEvent);
      this.props.closeMenus();
      screenX = e.originalEvent.x;
      screenY = e.originalEvent.y;
      timer = setTimeout(() => longTouch(e), touchDuration);
    });

    this.map.on('mouseup', () => {
      if (timer) clearTimeout(timer);
    });

    this.map.on('touchstart', (e) => {
      this.props.closeMenus();
      /* eslint-disable prefer-destructuring */
      screenX = e.originalEvent.touches.item(0).screenX;
      screenY = e.originalEvent.touches.item(0).screenY;
      /* eslint-enable prefer-destructuring */
      if (e.originalEvent.targetTouches.length === 1) {
        timer = setTimeout(() => longTouch(e), touchDuration);
      }
    });

    this.map.on('touchend', () => {
      if (timer) clearTimeout(timer);
    });

    this.map.on('move', (e) => {
      const event = e.originalEvent;
      if (!event) return;
      let currentX = event.x;
      let currentY = event.y;
      if (!currentX) {
        if (event.touches && event.touches.length > 0) {
          currentX = event.touches.item(event.touches.length - 1).screenX;
          currentY = event.touches.item(event.touches.length - 1).screenY;
        }
      }
      if (Math.abs(currentX - screenX) > 20 || Math.abs(currentY - screenY) > 20) {
        if (timer) clearTimeout(timer);
      }
    });
  }

  componentDidUpdate(prevProps) {
    const {
      position,
      waypoints,
      route,
      flyTo,
      flyToPoint,
      setSpinner,
      handleAddToRoute,
      handleRemoveFromRoute,
      redraw,
    } = this.props;
    const pois = this.props.pointOfInterests;

    const hasLanguageChanged = this.props.t !== prevProps.t;

    if (!this.map.getLayer('sailmate')) {
      this.map.addSource('sailmate', {
        type: 'raster',
        tiles: ['https://mapserver.sailmate.fi/fi/xyz/2024_01/{z}/{x}/{y}.png'],
        tileSize: 256,
      });
      this.map.addLayer({
        id: 'sailmate',
        type: 'raster',
        source: 'sailmate',
      });
    }

    if (this.props.route && (this.props.route.length === 0 || this.props.route.length === 1)) {
      removeLayerAndSource('route', 'route', this.map);
      removeLayerAndSource('waypoints', 'waypoints', this.map);
    }

    if ((route && prevProps.route !== route) || hasLanguageChanged) {
      removeLayer('symbols', this.map);
      removeLayer('symbols2', this.map);
      removeLayerAndSource('firstWP', 'firstWP', this.map);
      removeLayerAndSource('poisOnRoute', 'poisOnRoute', this.map);
      removeLayerAndSource('secondary', 'secondary', this.map);

      const features = this.props.route.filter(
        (poi) => poi.properties.type !== 'marker' && poi.properties.type !== 'secondary',
      );
      const featuresPoisOnRoute = this.props.route.filter(
        (poi) => poi.properties.type === 'marker' && poi.properties.type !== 'secondary',
      );
      const secondary = this.props.route.filter((poi) => poi.properties.type === 'secondary');

      this.map.addSource('firstWP', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      });

      this.map.addLayer({
        id: 'firstWP',
        type: 'circle',
        source: 'firstWP',
        paint: {
          'circle-radius': {
            base: 20,
            stops: [
              [22, 22],
              [32, 180],
            ],
          },
          'circle-color': '#3498db',
          'circle-opacity': 0.6,
        },
      });

      this.map.addSource('secondary', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: secondary,
        },
      });
      this.map.addLayer({
        id: 'secondary',
        type: 'circle',
        source: 'secondary',
        paint: {
          'circle-radius': {
            base: 20,
            stops: [
              [22, 22],
              [32, 180],
            ],
          },
          'circle-color': '#FFEE58',
          'circle-opacity': 0.6,
        },
      });

      this.map.addSource('poisOnRoute', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: featuresPoisOnRoute,
        },
      });

      this.map.addLayer({
        id: 'poisOnRoute',
        type: 'circle',
        source: 'poisOnRoute',
        paint: {
          'circle-radius': {
            base: 20,
            stops: [
              [22, 22],
              [32, 180],
            ],
          },
          'circle-color': '#e67e22',
          'circle-opacity': 0.6,
        },
      });

      this.map.addLayer({
        id: 'symbols',
        type: 'symbol',
        source: 'poisOnRoute',
        layout: {
          'icon-image': 'waste-basket-15',
          'text-field': this.props.t('remove'),
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0.7],
          'text-anchor': 'top',
          'text-size': 8,
        },
      });

      this.map.addLayer({
        id: 'symbols2',
        type: 'symbol',
        source: 'firstWP',
        layout: {
          'icon-image': 'waste-basket-15',
          'text-field': this.props.t('remove'),
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0.7],
          'text-anchor': 'top',
          'text-size': 8,
        },
      });
    }

    if (flyTo && flyTo !== prevProps.flyTo) {
      this.map.setCenter([flyTo.properties.lon, flyTo.properties.lat]);
    }

    if (position !== prevProps.position) {
      this.map.flyTo({ center: [position.longitude, position.latitude], zoom: 14 });
    }

    if (waypoints !== prevProps.waypoints) {
      if (waypoints.length > 0) {
        removeLayerAndSource('route', 'route', this.map);
        removeLayerAndSource('waypoints', 'waypoints', this.map);
        const wps = waypoints;
        const coordinates = wps.map((wp) => {
          if (!wp.lat) {
            return [wp.longitude, wp.latitude];
          }
          return [wp.lon, wp.lat];
        });

        const waypointFeatures = wps.map((wp) => {
          let coords = [wp.longitude, wp.latitude];
          if (!wp.latitude) {
            coords = [wp.lon, wp.lat];
          }
          return {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: coords,
            },
          };
        });

        this.map.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates,
            },
          },
        });

        this.map.addLayer(
          {
            id: 'route',
            type: 'line',
            source: 'route',
            layout: {
              'line-join': 'round',
              'line-cap': 'round',
            },
            paint: {
              'line-color': '#2962FF',
              'line-width': 5,
            },
          },
          'symbols',
        );

        this.map.addSource('waypoints', {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: waypointFeatures,
          },
        });

        this.map.addLayer(
          {
            id: 'waypoints',
            type: 'circle',
            source: 'waypoints',
            paint: {
              'circle-radius': {
                base: 3,
                stops: [
                  [12, 3.5],
                  [22, 180],
                ],
              },
              'circle-color': '#1A237E',
              'circle-opacity': 0.8,
            },
          },
          'symbols',
        );
      }
    }

    if (pois && prevProps.pointOfInterests !== pois) {
      removeLayerAndSource('markers', 'pois', this.map);

      const features = [];
      pois.forEach((poi) =>
        features.push({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [poi.point_of_interest.lon, poi.point_of_interest.lat],
          },
          properties: {
            title: poi.point_of_interest.name,
            'marker-symbol': 'ferry',
            type: 'marker',
            lat: poi.point_of_interest.lat,
            lon: poi.point_of_interest.lon,
            id: poi.point_of_interest.id,
          },
        }),
      );

      this.map.addSource('pois', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features,
        },
      });
      this.map.addLayer({
        id: 'markers',
        type: 'symbol',
        source: 'pois',
        layout: {
          'icon-image': '{marker-symbol}-15',
          'text-field': '{title}',
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 0.6],
          'text-anchor': 'top',
        },
      });
    }

    if (this.waypointsReceived(waypoints, prevProps.waypoints)) {
      setSpinner(false);
      const { route: prevRoute } = prevProps;
      const waypoint = waypoints[waypoints.length - 1];
      const routeEnd = prevRoute[prevRoute.length - 1];

      if (
        Math.abs(waypoint.lat - routeEnd.properties.lat) > 0.002 ||
        Math.abs(waypoint.lon - routeEnd.properties.lon) > 0.002
      ) {
        handleAddToRoute({
          type: 'Feature',
          geometry: {
            type: 'Point',
            coordinates: [waypoint.lon, waypoint.lat],
          },
          properties: {
            id: routeEnd.properties.id,
            title: `[${waypoint.lat.toFixed(3)}, ${waypoint.lon.toFixed(3)}]`,
            'marker-symbol': 'harbor',
            lat: waypoints[waypoints.length - 1].lat,
            lon: waypoints[waypoints.length - 1].lon,
            type: 'secondary',
          },
        });
      }
    }

    if (this.routeReceived(route, prevProps.route)) {
      if (route.length > 1 && route.filter((poi) => poi.properties.type !== 'secondary').length > 1) {
        setSpinner(true);
      } else if (route.length < prevProps.route.length && route.length > 0) {
        handleRemoveFromRoute(route[0]);
      }
      if (redraw) {
        this.props.getRoute(route);
      } else {
        flyToPoint(route[0]);
      }
    }

    // Check the windows size to determine whether or not to resize the Map.
    const { width, height } = this.props;

    if (width !== prevProps.width || height !== prevProps.height) {
      this.resizeMap();
    }

    // Scout Routes
    const { scoutRoutesActive, scoutRoutes } = this.props;

    const scoutRoutesPreviouslyInactive = !prevProps.scoutRoutesActive && scoutRoutesActive && scoutRoutes.length > 0;

    const scoutRoutesPreviouslyEmpty =
      prevProps.scoutRoutesActive && scoutRoutesActive && prevProps.scoutRoutes.length === 0 && scoutRoutes.length > 0;

    const shouldDrawScoutRoutes = scoutRoutesPreviouslyInactive || scoutRoutesPreviouslyEmpty;

    if (shouldDrawScoutRoutes) {
      scoutRoutes.forEach((scoutRoute, i) => {
        const layerName = `scoutRoute${i}`;
        const sourceName = `scoutRoute${i}`;
        removeLayerAndSource(layerName, sourceName, this.map);
        const { waypoints } = scoutRoute;
        const coordinates = waypoints.map(({ latitude, longitude }) => [longitude, latitude]);
        this.map.addSource(sourceName, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates,
            },
          },
        });

        this.map.addLayer({
          id: layerName,
          type: 'line',
          source: sourceName,
          layout: {
            'line-join': 'round',
            'line-cap': 'square',
          },
          paint: {
            'line-color': '#2962FF',
            'line-dasharray': [2, 0.5],
            'line-width': 5,
          },
        });
      }, 'symbols');

      const scoutRoutesCenter = (routes) => {
        const features = routes
          .flatMap((r) => r.waypoints)
          .map(({ latitude, longitude }) => point([longitude, latitude]));

        const centerFeature = center(featureCollection(features));
        const {
          geometry: { coordinates },
        } = centerFeature;
        return coordinates;
      };

      if (route.length === 0) {
        this.map.flyTo({ center: scoutRoutesCenter(scoutRoutes), zoom: 7 });
      }
    }

    if (prevProps.scoutRoutesActive && !scoutRoutesActive) {
      scoutRoutes.forEach((scoutRoute, i) => {
        const layerName = `scoutRoute${i}`;
        const sourceName = `scoutRoute${i}`;
        removeLayerAndSource(layerName, sourceName, this.map);
      });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  waypointsReceived = (newWaypoints, prevWaypoints) => {
    const hasWaypoints = newWaypoints && newWaypoints.length > 0;
    return hasWaypoints && newWaypoints !== prevWaypoints;
  };

  // eslint-disable-next-line class-methods-use-this
  routeReceived = (newRoute, prevRoute) => newRoute && newRoute !== prevRoute;

  render() {
    const { width, height } = this.props;

    return <div ref={this.mapRef} id="map" style={{ height, width }} />;
  }
}

Map.defaultProps = {
  position: undefined,
  flyTo: null,
};

Map.propTypes = {
  center: Types.center.isRequired,
  flyTo: Types.poi,
  position: Types.position,
  waypoints: PropTypes.arrayOf(Types.waypoint).isRequired,
  pointOfInterests: PropTypes.arrayOf(Types.poi).isRequired,
  route: Types.route.isRequired,
  handleAddToRoute: PropTypes.func.isRequired,
  handleRemoveFromRoute: PropTypes.func.isRequired,
  useAutomaticRoute: PropTypes.bool.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  getRoute: PropTypes.func.isRequired,
  flyToPoint: PropTypes.func.isRequired,
  redraw: PropTypes.bool.isRequired,
  setSpinner: PropTypes.func.isRequired,
  handleGetPois: PropTypes.func.isRequired,
  handleDrawLineRoute: PropTypes.func.isRequired,
  poiTap: PropTypes.func.isRequired,
  closeMenus: PropTypes.func.isRequired,
  scoutRoutesActive: PropTypes.bool.isRequired,
  scoutRoutes: Types.scoutRoutes.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Map);
