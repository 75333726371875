import React, { useState } from 'react';
import { List, ListItem } from 'material-ui';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import { useTranslation } from 'react-i18next';

const styles = {
  flat: {
    fontFamily: 'Roboto',
    fontSize: '10px',
  },
};

interface LoginFormProps {
  handleForgotPasswordClick: () => void;
  handleLoginRequest: (email?: string, password?: string) => void;
  handleToggleRegisterForm: (toggle: boolean) => void;
}

function LoginForm({ handleLoginRequest, handleToggleRegisterForm, handleForgotPasswordClick }: LoginFormProps) {
  const { t } = useTranslation();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  return (
    <div>
      <List>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }}>
          <TextField hintText={t('login-form.email')} type="mixed-content" fullWidth onChange={handleEmailChange} />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }}>
          <TextField hintText={t('login-form.password')} fullWidth type="password" onChange={handlePasswordChange} />
          <br />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="bottom-rounded">
          <RaisedButton label={t('login-form.login')} primary onMouseUp={() => handleLoginRequest(email, password)} />
          <FlatButton
            labelStyle={styles.flat}
            label={t('login-form.no-account')}
            secondary
            onMouseUp={() => handleToggleRegisterForm(true)}
          />
          <FlatButton
            labelStyle={styles.flat}
            label={t('login-form.forgot-password')}
            secondary
            onMouseUp={() => handleForgotPasswordClick()}
          />
        </ListItem>
      </List>
    </div>
  );
}

export default LoginForm;
