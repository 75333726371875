import crypto from 'crypto-js';
import moment from 'moment';

const createKey = () => 'sailmate';

export function encrypt(userInfo) {
  return crypto.AES.encrypt(JSON.stringify(userInfo), createKey());
}

const decrypt = (data) => {
  if (!data || Object.keys(data).length === 0) return {};
  const bytes = crypto.AES.decrypt(data.toString(), createKey());
  return JSON.parse(bytes.toString(crypto.enc.Utf8));
};

export function hasPaid(data) {
  if (!data) return false;
  const userInfo = decrypt(data);
  return userInfo.paid_until && moment().isBefore(userInfo.paid_until);
}

export function hasPaidScout(data) {
  if (!data) return false;
  const userInfo = decrypt(data);
  return userInfo.scout_paid_until && moment().isBefore(userInfo.scout_paid_until);
}

export function firstName(data) {
  const userInfo = decrypt(data);
  return userInfo.first_name;
}

export function lastName(data) {
  const userInfo = decrypt(data);
  return userInfo.last_name;
}

export function initials(data) {
  const userInfo = decrypt(data);
  if (!userInfo.first_name || !userInfo.last_name) return '';
  return [userInfo.first_name[0], userInfo.last_name[0]];
}

export function email(data) {
  const userInfo = decrypt(data);
  return userInfo.email;
}

export function paidUntil(data) {
  const userInfo = decrypt(data);
  return userInfo.paid_until;
}

export function scoutPaidUntil(data) {
  const userInfo = decrypt(data);
  return userInfo.scout_paid_until;
}
