import React from 'react';
import PropTypes from 'prop-types';

import { Responsive, WidthProvider } from 'react-grid-layout';

import { useTranslation } from 'react-i18next';

import { useHistory } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  toggleRegister,
  toggleLogin,
  register,
  fetchUserInfo,
  fetchStoredRoutes,
  toggleRoute,
  toggleAccount,
  login,
  loginWithFacebook,
  showMessage,
  setSpinner,
  requestPasswordChange,
  changePassword,
} from '../actions';

import Types from '../types';

import LoginForm from '../components/LoginForm';
import RegisterForm from '../components/RegisterForm';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

function Account({
  user,
  showLoginForm,
  showRegisterForm,
  layouts,
  appId,
  redirectUri,
  toggleRegister,
  toggleLogin,
  register,
  fetchUserInfo,
  fetchStoredRoutes,
  toggleRoute,
  toggleAccount,
  login,
  loginWithFacebook,
  showMessage,
  setSpinner,
}) {
  const { t } = useTranslation();
  const history = useHistory();

  const handleToggleRegisterForm = (show) => {
    toggleRegister(show);
    toggleLogin(!show);
    toggleAccount(!show);
  };

  const handleRegisterRequest = (emailAddress, password, passwordConfirmation, firstName, lastName) =>
    register(emailAddress, password, passwordConfirmation, firstName, lastName);

  const handleUserInfoRequest = (user) => {
    fetchUserInfo(user);
    fetchStoredRoutes(user);
  };

  const handleAccountTap = (show) => {
    toggleAccount(show);
    toggleRoute(false);
    if (show) {
      handleUserInfoRequest(user);
    }
  };

  const handleForgotPasswordClick = () => history.push('/forgot-password');

  const handleLoginRequest = (emailAddress, password) => {
    setSpinner(true);
    login(emailAddress, password);
  };

  const handleLoginWithFacebookRequest = (response) => {
    setSpinner(true);
    if (response.status !== 'connected') {
      showMessage(t('messages.login-failed'));
      return;
    }
    loginWithFacebook(response.authResponse.accessToken);
  };

  const breakpoints = {
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  };
  const cols = {
    lg: 12,
    md: 10,
    sm: 6,
    xs: 4,
    xxs: 2,
  };

  return (
    <div>
      <ResponsiveReactGridLayout className="layout" layouts={layouts} breakpoints={breakpoints} cols={cols}>
        <div key="a">
          {showLoginForm && (
            <div>
              <div style={{ fontFamily: 'Lato', color: '#d9e0e2' }}>
                <h4>
                  <span style={{ marginRight: 20 }} className="icon-sails" />
                  {t('login-form.title')}
                </h4>
                {window.location.href.includes('checkout') && (
                  <div
                    style={{
                      backgroundColor: '#ffffff',
                      color: '#000000',
                      padding: '10px',
                    }}
                  >
                    {t('login-form.buy-premium-message.description1')}
                    <br />
                    <br />
                    {t('login-form.buy-premium-message.description2')}
                    <br />
                    <br />
                    {t('login-form.buy-premium-message.description3')}
                  </div>
                )}
              </div>
              <LoginForm
                showLoginForm={showLoginForm}
                handleAccountClose={handleAccountTap}
                handleForgotPasswordClick={handleForgotPasswordClick}
                handleLoginRequest={handleLoginRequest}
                handleLoginWithFacebookRequest={handleLoginWithFacebookRequest}
                handleToggleRegisterForm={handleToggleRegisterForm}
                appId={appId}
                redirectURI={redirectUri}
              />
            </div>
          )}

          {showRegisterForm && (
            <div>
              <div>
                <h4 style={{ fontFamily: 'Lato', color: '#d9e0e2' }}>
                  <span style={{ color: '#d9e0e2', marginRight: 20 }} className="icon-sails" />
                  {t('account.create-account')}
                </h4>
              </div>
              <RegisterForm
                showRegisterForm={showRegisterForm}
                handleRegisterRequest={handleRegisterRequest}
                handleToggleRegisterForm={handleToggleRegisterForm}
              />
            </div>
          )}
        </div>
      </ResponsiveReactGridLayout>
    </div>
  );
}

Account.propTypes = {
  user: Types.user.isRequired,
  showLoginForm: PropTypes.bool.isRequired,
  showRegisterForm: PropTypes.bool.isRequired,
  layouts: Types.layouts.isRequired,
  appId: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  toggleRegister: PropTypes.func.isRequired,
  toggleLogin: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  fetchStoredRoutes: PropTypes.func.isRequired,
  toggleRoute: PropTypes.func.isRequired,
  toggleAccount: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  loginWithFacebook: PropTypes.func.isRequired,
  showMessage: PropTypes.func.isRequired,
  setSpinner: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default compose(
  connect(
    ({ user }) => ({
      user: user.user,
      showLoginForm: user.showLoginForm,
      showRegisterForm: user.showRegisterForm,
      showForgotPasswordForm: user.showForgotPasswordForm,
      isForgotPasswordLoading: user.forgotPasswordFormLoading,
    }),
    {
      toggleRegister,
      toggleLogin,
      register,
      fetchUserInfo,
      fetchStoredRoutes,
      toggleRoute,
      toggleAccount,
      login,
      loginWithFacebook,
      showMessage,
      setSpinner,
      requestPasswordChange,
      changePassword,
    },
  ),
)(Account);
