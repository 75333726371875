import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import moment from 'moment';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { User } from '../betterTypes';

const SCOUT_SUBSCRIPTION_ID = process.env.REACT_APP_SCOUT_SUBSCRIPTION_ID;
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

interface ScoutSubscriptionPanelProps {
  user: User;
  scoutPaidUntil: string;
  hasPaid: boolean;
}

function ScoutSubscriptionPanel({ user, scoutPaidUntil, hasPaid }: ScoutSubscriptionPanelProps) {
  const { i18n, t } = useTranslation();

  const formatDate = (date: string) => moment(date).format('DD.MM.YYYY');
  const infoText = scoutPaidUntil ? t('subscriptions.scout-valid-until', { date: formatDate(scoutPaidUntil) }) : '';

  const link = `${API_ENDPOINT}/payments/create_stripe_session?priceId=${SCOUT_SUBSCRIPTION_ID}&locale=${i18n.resolvedLanguage}&email=${user.email}`;

  return (
    <div>
      <h6>{t('subscriptions.scout-premium')}</h6>

      {hasPaid && <div style={{ fontSize: 10, textAlign: 'center' }}>{infoText}</div>}

      {!hasPaid && (
        <RaisedButton
          primary
          fullWidth
          label={t('subscriptions.buy-scout-premium')}
          style={{ width: '100%' }}
          labelStyle={{ fontSize: 10 }}
          href={link}
        />
      )}
    </div>
  );
}

export default connect(({ user }: { user: { user: User } }) => ({
  user: user.user,
}))(ScoutSubscriptionPanel);
