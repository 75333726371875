import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import loggerMiddleware from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { save, load } from 'redux-localstorage-simple';
import reducer from '../reducer';
import App from './App';
import '../component.scss';

// STORE

const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

const states = ['user', 'tips'];
const localStorageSave = save({ states });
const composeEnhancers =
  typeof window === 'object' &&
  /* eslint-disable no-underscore-dangle */
  /* eslint-disable no-undef */
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;
/* eslint-enable no-underscore-dangle */
/* eslint-enable no-undef */
const store = createStore(
  reducer,
  load({ states }),
  composeEnhancers(applyMiddleware(loggerMiddleware, thunkMiddleware, localStorageSave)),
);

// ROOT OBJECT
export default class extends Component {
  componentDidMount() {
    /* eslint-disable no-undef, func-names */
    (function (d, s, id) {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = '//connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');
    window.fbAsyncInit = () => {
      FB.init({
        appId: APP_ID,
        xfbml: true,
        version: 'v2.6',
      });
    };
  }

  render() {
    return (
      <Provider store={store}>
        <Router history={Router.hashHistory}>
          <App />
        </Router>
      </Provider>
    );
  }
}
