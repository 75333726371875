import React, { Component } from 'react';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { startsWith } from 'lodash';

import baseTheme from 'material-ui/styles/baseThemes/lightBaseTheme';
import getMuiTheme from 'material-ui/styles/getMuiTheme';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

import i18n from 'i18next';

import { withTranslation } from 'react-i18next';

import { Route, Switch, withRouter } from 'react-router-dom';

import withSizes from 'react-sizes';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { showMessage, loginWithFacebook, hideFlashScreen, setSpinner } from '../actions';

import MapContainer from './MapContainer';
import RouteMenuContainer from './RouteMenuContainer';
import ToolBarContainer from './ToolbarContainer';
import SnackbarContainer from './SnackbarContainer';
import Account from './Account';
import AccountMenuContainer from './AccountMenuContainer';
import FlashScreen from '../components/FlashScreen';
import CheckoutContainer from './CheckoutContainer';
import ScoutCheckoutContainer from './ScoutCheckoutContainer';
import TipsContainer from './TipsContainer';
import FloatingMenuContainer from './FloatingMenuContainer';
import AutomaticRouteToggleContainer from './AutomaticRouteToggleContainer';
import SpinnerContainer from './SpinnerContainer';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';

import types from '../types';
import layouts from '../layouts';

const ReactGA = require('react-ga');

const GA_ID = process.env.REACT_APP_GA_ID;
ReactGA.initialize(GA_ID);
ReactGA.plugin.require('ecommerce');

const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;
const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI;

class App extends Component {
  constructor(props) {
    super(props);

    const { location } = props;

    const queryParams = new URLSearchParams(location.search);
    const lang = queryParams.get('lang');
    if (lang !== null && ['fi', 'en'].includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }

  componentDidMount() {
    const {
      match: { params },
      dispatch,
    } = this.props;
    /* eslint-disable no-undef */
    if (params.type === 'fb') {
      const handleLoginStatus = (response) => this.handleLoginWithFacebookRequest(response);
      setTimeout(() => FB.getLoginStatus(handleLoginStatus, true), 2000);
    }
    /* eslint-enable no-undef */
    setTimeout(() => {
      dispatch(hideFlashScreen());
    }, 1000);

    // Translate page title
    setTimeout(() => {
      document.title = this.props.t('page-title');
    }, 1000);
  }

  // eslint-disable-next-line class-methods-use-this
  isCheckout = (location) => startsWith(location.hash, '#/checkout');

  // eslint-disable-next-line class-methods-use-this
  isScoutCheckout = (location) => startsWith(location.hash, '#/scout-checkout');

  decideMainComponent = (location, user, width, height, showSpinner, showFloatingMenu) => {
    if (this.isCheckout(location)) {
      return <CheckoutContainer layouts={layouts} user={user} />;
    }

    if (this.isScoutCheckout(location)) {
      return <ScoutCheckoutContainer layouts={layouts} user={user} />;
    }

    return (
      <div>
        <div>
          <MapContainer width={width} height={height} />
        </div>
        {!showSpinner && (
          <div>
            <ToolBarContainer />
          </div>
        )}
        {showFloatingMenu && <FloatingMenuContainer />}
        <AutomaticRouteToggleContainer />
        <RouteMenuContainer />
        <AccountMenuContainer />
      </div>
    );
  };

  handleLoginWithFacebookRequest = (response) => {
    const { dispatch } = this.props;
    dispatch(setSpinner(true));
    if (response.status !== 'connected') {
      dispatch(showMessage(this.props.t('messages.login-canceled')));
      return;
    }
    dispatch(loginWithFacebook(response.authResponse.accessToken));
  };

  render() {
    const {
      showLoginForm,
      showRegisterForm,
      showFlashScreen,
      showRouteMenu,
      showAccountMenu,
      showForgotPasswordForm,
      location,
      width,
      height,
      user,
      showSpinner,
    } = this.props;
    /* eslint-disable no-undef */
    ReactGA.pageview(window.location.pathname);
    /* eslint-enable no-undef */
    const showFlash = showFlashScreen && user && location.pathname !== 'checkout';
    const showFloatingMenu = !showSpinner && !showRouteMenu && !showAccountMenu;

    const selectedView = showLoginForm || showRegisterForm || showForgotPasswordForm ? 'account' : 'map';

    const renderApp = () => (
      <div>
        {showFlash && <FlashScreen height={height} width={width} />}
        {this.isCheckout(location) || this.isScoutCheckout(location) ? null : <TipsContainer />}
        {selectedView === 'map' && (
          <div>{this.decideMainComponent(location, user, width, height, showSpinner, showFloatingMenu)}</div>
        )}

        {selectedView === 'account' && <Account appId={APP_ID} redirectUri={REDIRECT_URI} layouts={layouts} />}

        <SpinnerContainer height={height} width={width} />
        <SnackbarContainer />
      </div>
    );

    return (
      <MuiThemeProvider muiTheme={getMuiTheme(baseTheme)}>
        <div>
          <Switch>
            <Route path="/users/password/edit" component={ResetPassword} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/" render={renderApp} />
            <Route path="/login_type/:type" render={renderApp} />
            <Route path="/checkout" render={renderApp} />
            <Route path="/scout-checkout" render={renderApp} />
          </Switch>
        </div>
      </MuiThemeProvider>
    );
  }
}

App.defaultProps = {
  user: null,
};

App.propTypes = {
  showLoginForm: PropTypes.bool.isRequired,
  showRegisterForm: PropTypes.bool.isRequired,
  showForgotPasswordForm: PropTypes.bool.isRequired,
  showFlashScreen: PropTypes.bool.isRequired,
  showRouteMenu: PropTypes.bool.isRequired,
  showAccountMenu: PropTypes.bool.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string.isRequired,
  }).isRequired,
  match: PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    params: PropTypes.object,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  user: types.user,
  t: PropTypes.func.isRequired,
};

function mapStateToProps({ routes, user, flashScreen, spinner }) {
  return {
    route: routes.route,
    showRouteMenu: routes.showRouteMenu,
    useAutomaticRoute: routes.useAutomaticRoute,
    showAccountMenu: user.showAccountMenu,
    showLoginForm: user.showLoginForm,
    showRegisterForm: user.showRegisterForm,
    showForgotPasswordForm: user.showForgotPasswordForm,
    waypoints: routes.waypoints,
    user: user.user,
    userInfo: user.userInfo,
    redraw: routes.redraw,
    showFlashScreen: flashScreen.showFlashScreen,
    showSpinner: spinner.showSpinner,
  };
}

const AppComponent = compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps),
  withSizes(({ width, height }) => ({ width, height })),
)(App);

export default process.env.NODE_ENV === 'development' ? hot(module)(AppComponent) : AppComponent;
