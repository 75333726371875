import React from 'react';

import { connect } from 'react-redux';
import { hideMessage } from '../actions';

import Snackbar from '../components/Snackbar';

interface SnackbarContainerProps {
  showSnackbar: boolean;
  snackbarMessage: string;
  hideMessage: (message: string) => void;
}

function SnackbarContainer({ showSnackbar, snackbarMessage, hideMessage }: SnackbarContainerProps) {
  return (
    <Snackbar
      showSnackbar={showSnackbar}
      snackbarMessage={snackbarMessage}
      handleRequestClose={() => hideMessage('')}
    />
  );
}

export default connect(
  ({ messages }: { messages: SnackbarContainerProps }) => ({
    showSnackbar: messages.showSnackbar,
    snackbarMessage: messages.snackbarMessage,
  }),
  { hideMessage },
)(SnackbarContainer);
