import React from 'react';

import { useLocation } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Layouts } from 'react-grid-layout';

import Checkout from '../components/Checkout';

import { paidUntil, hasPaid } from '../utils/userInfoUtils';

interface CheckoutContainerProps {
  userInfo: string;
  layouts: Layouts;
}

function CheckoutContainer({ layouts, userInfo }: CheckoutContainerProps) {
  const location = useLocation();

  if (location.pathname === 'checkout') return null;

  return <Checkout layouts={layouts} paidUntil={paidUntil(userInfo)} hasPaid={hasPaid(userInfo)} />;
}

export default compose(connect(({ user }: { user: { userInfo: string } }) => ({ userInfo: user.userInfo })))(
  CheckoutContainer,
);
