import React from 'react';
import { FlatButton, FontIcon, IconButton, RaisedButton } from 'material-ui';

import { useTranslation } from 'react-i18next';

import { Card, CardActions, CardHeader, CardMedia, CardTitle, CardText } from 'material-ui/Card';
import { Responsive, WidthProvider } from 'react-grid-layout';
import lineAutomaticImage from '../images/line-automatic.gif';
import tapToRouteImage from '../images/tap-to-route.gif';
import saveRouteImage from '../images/save-route.gif';
import toggleLanguage from '../utils/languageUtils';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

interface TipsProps {
  handleInfoPageChange: (page?: number) => void;
  handleSeenInfo: () => void;
  infoPage: number;
  height: number;
  width: number;
}

const largeLayout = [
  {
    i: 'a',
    x: 4,
    y: 0,
    w: 4,
    h: 2,
    static: true,
  },
];
const mediumLayout = [
  {
    i: 'a',
    x: 3,
    y: 0,
    w: 4,
    h: 2,
    static: true,
  },
];
const smallLayout = [
  {
    i: 'a',
    x: 1,
    y: 0,
    w: 4,
    h: 2,
    static: true,
  },
];

const layouts = { lg: largeLayout, md: mediumLayout, sm: smallLayout };

function Tips({ height, width, infoPage, handleInfoPageChange, handleSeenInfo }: TipsProps) {
  const { t, i18n } = useTranslation();

  const handleLanguageToggle = () => {
    toggleLanguage(i18n);
  };

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: '#ffffff',
        height,
        width,
        opacity: 1,
        zIndex: 999999999999,
      }}
      className="flash"
    >
      <ResponsiveReactGridLayout
        className="layout"
        layouts={layouts}
        breakpoints={{
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        }}
        cols={{
          lg: 12,
          md: 10,
          sm: 6,
          xs: 4,
          xxs: 2,
        }}
      >
        <div key="a">
          {!infoPage ? (
            <Card style={{ padding: 20 }}>
              <CardHeader
                subtitle={t('tips.step1.subtitle')}
                style={{ backgroundColor: '#ecf0f1' }}
                textStyle={{ display: 'contents' }}
                title={
                  <>
                    {t('tips.tip')} 1/3
                    <IconButton style={{ float: 'right', marginTop: -10 }} onMouseUp={() => handleSeenInfo()}>
                      <FontIcon className="material-icons">close</FontIcon>
                    </IconButton>
                  </>
                }
              />
              <CardMedia>
                <img src={lineAutomaticImage} alt="" />
              </CardMedia>
              <CardTitle title={t('tips.routing-type.title')} subtitle={t('tips.routing-type.subtitle')} />
              <CardText>{t('tips.routing-type.text')}</CardText>
              <CardActions>
                <FlatButton disabled label={t('previous')} />
                <RaisedButton label={t('next')} onMouseUp={() => handleInfoPageChange(1)} labelPosition="before" />
                <FlatButton
                  label={t('change-language')}
                  onMouseUp={handleLanguageToggle}
                  secondary
                  style={{ float: 'right' }}
                />
              </CardActions>
            </Card>
          ) : (
            <div />
          )}
          {infoPage === 1 && (
            <Card style={{ padding: 20 }}>
              <CardHeader
                subtitle={t('tips.step2.subtitle')}
                style={{ backgroundColor: '#ecf0f1' }}
                textStyle={{ display: 'contents' }}
                title={
                  <>
                    {t('tips.tip')} 2/3
                    <IconButton style={{ float: 'right', marginTop: -10 }} onMouseUp={() => handleSeenInfo()}>
                      <FontIcon className="material-icons">close</FontIcon>
                    </IconButton>
                  </>
                }
              />
              <CardMedia>
                <img src={tapToRouteImage} alt="" />
              </CardMedia>
              <CardTitle title={t('tips.adding-waypoint.title')} subtitle={t('tips.adding-waypoint.subtitle')} />
              <CardText>{t('tips.adding-waypoint.text')}</CardText>
              <CardActions>
                <FlatButton label={t('previous')} onMouseUp={() => handleInfoPageChange(undefined)} />
                <FlatButton label={t('next')} onMouseUp={() => handleInfoPageChange(2)} labelPosition="before" />
              </CardActions>
            </Card>
          )}
          {infoPage === 2 ? (
            <Card style={{ padding: 20 }}>
              <CardHeader
                subtitle={t('tips.step3.subtitle')}
                style={{ backgroundColor: '#ecf0f1' }}
                textStyle={{ display: 'contents' }}
                title={
                  <>
                    {t('tips.tip')} 3/3
                    <IconButton style={{ float: 'right', marginTop: -10 }} onMouseUp={() => handleSeenInfo()}>
                      <FontIcon className="material-icons">close</FontIcon>
                    </IconButton>
                  </>
                }
              />
              <CardMedia>
                <img src={saveRouteImage} alt="" />
              </CardMedia>
              <CardTitle title={t('tips.saving-route.title')} subtitle={t('tips.saving-route.subtitle')} />
              <CardText>{t('tips.saving-route.text')}</CardText>
              <CardActions>
                <FlatButton label={t('previous')} onMouseUp={() => handleInfoPageChange(1)} />
                <FlatButton disabled label={t('next')} labelPosition="before" />
              </CardActions>
            </Card>
          ) : (
            <div />
          )}
        </div>
      </ResponsiveReactGridLayout>
    </div>
  );
}

export default Tips;
