import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import moment from 'moment';
import { connect } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { User } from '../betterTypes';

const PREMIUM_SUBSCRIPTION_ID = process.env.REACT_APP_PREMIUM_SUBSCRIPTION_ID;
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

interface SubscriptionPanelProps {
  user: User;
  paidUntil: string;
  hasPaid: boolean;
  infoFontSize?: number;
}

function SubscriptionPanel({ user, paidUntil, hasPaid, infoFontSize }: SubscriptionPanelProps) {
  const { i18n, t } = useTranslation();

  const date = moment(paidUntil).format('DD.MM.YYYY');

  const infoText = paidUntil ? t('subscriptions.valid-until', { date }) : '';

  const link = `${API_ENDPOINT}/payments/create_stripe_session?priceId=${PREMIUM_SUBSCRIPTION_ID}&locale=${i18n.resolvedLanguage}&email=${user.email}`;

  return (
    <div>
      <h6>{t('subscriptions.premium')}</h6>

      {hasPaid && <div style={{ fontSize: infoFontSize, textAlign: 'center', marginBottom: '10px' }}>{infoText}</div>}

      {!hasPaid && (
        <RaisedButton
          primary
          fullWidth
          label={t('subscriptions.buy-premium')}
          style={{ width: '100%' }}
          labelStyle={{ fontSize: 10 }}
          href={link}
        />
      )}
    </div>
  );
}

SubscriptionPanel.defaultProps = {
  infoFontSize: 10,
};

export default connect(({ user }: { user: { user: User } }) => ({
  user: user.user,
}))(SubscriptionPanel);
