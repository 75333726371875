import React from 'react';
import PropTypes from 'prop-types';

import { Responsive, WidthProvider } from 'react-grid-layout';

import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { requestPasswordChange } from '../actions';

import layouts from '../layouts';

import ForgotPasswordForm from '../components/ForgotPasswordForm';
import SnackbarContainer from './SnackbarContainer';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const breakpoints = {
  lg: 1200,
  md: 996,
  sm: 768,
  xs: 480,
  xxs: 0,
};
const cols = {
  lg: 12,
  md: 10,
  sm: 6,
  xs: 4,
  xxs: 2,
};

function ForgotPassword({ requestPasswordChange, isForgotPasswordLoading }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <ResponsiveReactGridLayout className="layout" layouts={layouts} breakpoints={breakpoints} cols={cols}>
        <div key="a">
          <div>
            <h4 style={{ fontFamily: 'Lato', color: '#d9e0e2' }}>
              <span style={{ color: '#d9e0e2', marginRight: 20 }} className="icon-sails" />
              {t('forgot.forgot-password')}
            </h4>
          </div>
          <ForgotPasswordForm
            handlePasswordChangeRequest={requestPasswordChange}
            handleTogglePasswordChangeForm={() => history.push('/')}
            isLoading={isForgotPasswordLoading}
          />
        </div>
      </ResponsiveReactGridLayout>
      <SnackbarContainer />
    </div>
  );
}

ForgotPassword.propTypes = {
  requestPasswordChange: PropTypes.func.isRequired,
  isForgotPasswordLoading: PropTypes.bool.isRequired,
};

export default compose(
  connect(
    ({ user }) => ({
      isForgotPasswordLoading: user.forgotPasswordFormLoading,
    }),
    { requestPasswordChange },
  ),
)(ForgotPassword);
