import React from 'react';
import PropTypes from 'prop-types';

import withSizes from 'react-sizes';

import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  addToRoute,
  removeFromRoute,
  saveRoute,
  deleteRoute,
  moveForwardOnRoute,
  moveBackOnRoute,
  toggleRoute,
  fetchUserInfo,
  fetchStoredRoutes,
  fetchStoredRoute,
  getRoute,
  clearRoute,
  routeSavedReceived,
  toggleAccount,
  flyTo,
  setSpinner,
  toggleScoutRoutes,
  exportRoute,
} from '../actions';

import Types from '../types';

import RouteMenu from '../components/RouteMenu';

import { hasPaid } from '../utils/userInfoUtils';

function RouteMenuContainer({
  poi,
  pointOfInterests,
  route,
  waypoints,
  user,
  userInfo,
  routeName,
  showRouteMenu,
  storedRoutes,
  showMessage,
  addToRoute,
  removeFromRoute,
  saveRoute,
  deleteRoute,
  moveForwardOnRoute,
  moveBackOnRoute,
  toggleRoute,
  fetchUserInfo,
  fetchStoredRoutes,
  fetchStoredRoute,
  getRoute,
  clearRoute,
  routeSavedReceived,
  toggleAccount,
  flyTo,
  setSpinner,
  toggleScoutRoutes,
  scoutRoutesActive,
  exportRoute,
  width,
  height,
}) {
  const handleUserInfoRequest = (user) => {
    fetchUserInfo(user);
    fetchStoredRoutes(user);
  };

  const handleRouteTap = (show) => {
    toggleRoute(show);
    if (show) {
      handleUserInfoRequest(user);
    }
  };

  const handleDrawRoute = (waypoints) => {
    if (waypoints.length > 1) {
      setSpinner(true);
    }
    getRoute(waypoints);
  };

  const handleClearRoute = (route) => {
    clearRoute(route);
    routeSavedReceived({});
  };

  const handleAccountTap = (show) => {
    toggleAccount(show);
    toggleRoute(false);
    if (show) {
      handleUserInfoRequest(user);
    }
  };

  const handleFetchRouteRequest = (id) => {
    fetchStoredRoute(user, id, pointOfInterests);
  };

  const handleSaveRoute = (name) => {
    saveRoute(route, waypoints, name, user);
  };

  const handleDeleteRouteWithId = (id) => {
    const storedRoute = storedRoutes.filter((route) => route.id === id)[0];
    deleteRoute(user, storedRoute);
  };

  return (
    <RouteMenu
      poi={poi}
      addToRoute={addToRoute}
      removeFromRoute={removeFromRoute}
      moveForwardOnRoute={moveForwardOnRoute}
      moveBackOnRoute={moveBackOnRoute}
      route={route}
      tap={handleRouteTap}
      showRouteMenu={showRouteMenu}
      drawRoute={handleDrawRoute}
      clearRoute={handleClearRoute}
      showMessage={showMessage}
      waypoints={waypoints}
      hasPaid={hasPaid(userInfo)}
      toggleAccount={handleAccountTap}
      handleFetchRouteRequest={handleFetchRouteRequest}
      handleSaveRoute={handleSaveRoute}
      handleDeleteRoute={handleDeleteRouteWithId}
      routeName={routeName}
      storedRoutes={storedRoutes}
      userInfo={userInfo}
      flyTo={flyTo}
      user={user}
      handleScoutToggle={toggleScoutRoutes}
      scoutRoutesActive={scoutRoutesActive}
      handleExportRoute={exportRoute}
      width={width}
      height={height}
    />
  );
}

RouteMenuContainer.defaultProps = {
  poi: null,
  pointOfInterests: [],
  route: [],
  routeName: '',
  storedRoutes: [],
  waypoints: [],
  userInfo: '',
  user: null,
};

RouteMenuContainer.propTypes = {
  poi: Types.poi,
  pointOfInterests: PropTypes.arrayOf(Types.poi),
  route: Types.route,
  waypoints: PropTypes.arrayOf(Types.waypoint),
  user: Types.user,
  userInfo: PropTypes.string,
  routeName: PropTypes.string,
  storedRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
      user_id: PropTypes.number.isRequired,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      length: PropTypes.number.isRequired,
    }),
  ),
  showRouteMenu: PropTypes.bool.isRequired,
  showMessage: PropTypes.bool.isRequired,
  addToRoute: PropTypes.func.isRequired,
  removeFromRoute: PropTypes.func.isRequired,
  saveRoute: PropTypes.func.isRequired,
  deleteRoute: PropTypes.func.isRequired,
  moveForwardOnRoute: PropTypes.func.isRequired,
  moveBackOnRoute: PropTypes.func.isRequired,
  toggleRoute: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  fetchStoredRoutes: PropTypes.func.isRequired,
  fetchStoredRoute: PropTypes.func.isRequired,
  getRoute: PropTypes.func.isRequired,
  clearRoute: PropTypes.func.isRequired,
  routeSavedReceived: PropTypes.func.isRequired,
  toggleAccount: PropTypes.func.isRequired,
  flyTo: PropTypes.func.isRequired,
  setSpinner: PropTypes.func.isRequired,
  toggleScoutRoutes: PropTypes.func.isRequired,
  scoutRoutesActive: PropTypes.bool.isRequired,
  exportRoute: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
};

export default compose(
  connect(
    ({ routes, user, messages }) => ({
      poi: routes.poi,
      pointOfInterests: routes.pointOfInterests,
      route: routes.route,
      waypoints: routes.waypoints,
      routeName: routes.routeName,
      showRouteMenu: routes.showRouteMenu,
      storedRoutes: routes.storedRoutes,
      userInfo: user.userInfo,
      user: user.user,
      showMessage: messages.showSnackbar,
      scoutRoutesActive: routes.scoutRoutesActive,
    }),
    {
      addToRoute,
      removeFromRoute,
      saveRoute,
      deleteRoute,
      moveForwardOnRoute,
      moveBackOnRoute,
      toggleRoute,
      fetchUserInfo,
      fetchStoredRoutes,
      fetchStoredRoute,
      getRoute,
      clearRoute,
      routeSavedReceived,
      toggleAccount,
      flyTo,
      setSpinner,
      toggleScoutRoutes,
      exportRoute,
    },
  ),
  withSizes(({ width, height }) => ({ width, height })),
)(RouteMenuContainer);
