import React from 'react';
import { CircularProgress } from 'material-ui';

interface SpinnerProps {
  width: number;
  height: number;
  handleBackdropClick: (event: React.MouseEvent<HTMLElement>) => void;
}

function Spinner({ width, height, handleBackdropClick }: SpinnerProps) {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={handleBackdropClick}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: '#ffffff',
        height,
        width,
        opacity: 0.5,
      }}
    >
      <CircularProgress
        size={2}
        style={{
          position: 'absolute',
          top: height / 2 - 60,
          left: width / 2 - 60,
        }}
      />
    </div>
  );
}

export default Spinner;
