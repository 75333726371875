import React, { useEffect, useState } from 'react';
import { List, ListItem } from 'material-ui/List';
import { Drawer, MenuItem, FlatButton } from 'material-ui';
import Avatar from 'material-ui/Avatar';
import Divider from 'material-ui/Divider';
import FontIcon from 'material-ui/FontIcon';
import Toggle from 'material-ui/Toggle';
import { useTranslation } from 'react-i18next';

import SubscriptionPanel from './SubscriptionPanel';
import ScoutSubscriptionPanel from './ScoutSubscriptionPanel';
import {
  hasPaid,
  firstName,
  lastName,
  initials,
  email,
  paidUntil,
  hasPaidScout,
  scoutPaidUntil,
} from '../utils/userInfoUtils';

const styles = {
  button: {
    width: '90%',
    margin: '5%',
  },
  scoutRoutesBlock: {
    padding: '5%',
  },
};

interface AccountMenuProps {
  showAccountMenu: boolean;
  tap: (a: boolean) => void;
  handleLogout: () => void;
  handleShowInfo: () => void;
  handleLanguageToggle: () => void;
  userInfo: string;
  handleScoutToggle: (toggle: boolean) => void;
  scoutRoutesActive: boolean;
  handleFetchUserInfo: () => void;
}

function AccountMenu(props: AccountMenuProps) {
  const {
    showAccountMenu,
    userInfo,
    tap,
    handleShowInfo,
    handleLanguageToggle,
    handleLogout,
    handleScoutToggle,
    scoutRoutesActive,
    handleFetchUserInfo,
  } = props;

  const { t } = useTranslation();
  const [userInfoFetched, setUserInfoFetched] = useState(false);

  useEffect(() => {
    if (showAccountMenu && !userInfoFetched) {
      handleFetchUserInfo();
      setUserInfoFetched(true);
    }
  }, [showAccountMenu, userInfoFetched]);

  const initialsText = userInfo ? (initials(userInfo) || []).join('') : '';
  const fullName = `${firstName(userInfo)} ${lastName(userInfo)}`;
  const userEmail = email(userInfo);

  return (
    <Drawer open={showAccountMenu} openSecondary>
      <MenuItem
        style={{ backgroundColor: '#ECEFF1' }}
        onClick={() => tap(false)}
        leftIcon={<FontIcon className="material-icons">chevron_right</FontIcon>}
      >
        <span style={{ fontFamily: 'Lato', color: '#CFD8DC', marginTop: 20 }}>SAILMATE</span>
        <span style={{ color: '#42A5F5', marginLeft: 20, fontSize: 12 }} className="icon-sails" />
      </MenuItem>
      <Divider />
      <List>
        <ListItem
          disabled
          style={{ fontFamily: 'Roboto' }}
          leftAvatar={<Avatar>{initialsText}</Avatar>}
          primaryText={fullName}
          secondaryText={userEmail}
        />
      </List>
      <List>
        {hasPaidScout(userInfo) && (
          <div style={styles.scoutRoutesBlock}>
            <Toggle
              label={t('routes.scout-routes')}
              onToggle={(_e, toggled) => handleScoutToggle(toggled)}
              toggled={scoutRoutesActive}
            />
          </div>
        )}
      </List>
      <ListItem disabled>
        <SubscriptionPanel paidUntil={paidUntil(userInfo)} hasPaid={hasPaid(userInfo)} />
        <ScoutSubscriptionPanel scoutPaidUntil={scoutPaidUntil(userInfo)} hasPaid={hasPaidScout(userInfo)} />
      </ListItem>
      <MenuItem disabled>
        <div style={{ fontSize: '10px', textAlign: 'center' }}>{t('routes.instructions')}</div>
      </MenuItem>
      <div>
        <FlatButton style={styles.button} label={t('show-instructions')} onMouseUp={() => handleShowInfo()} />
        <FlatButton style={styles.button} label={t('change-language')} onMouseUp={handleLanguageToggle} />
        <FlatButton style={styles.button} label={t('sign.out')} onMouseUp={() => handleLogout()} />
      </div>
    </Drawer>
  );
}

export default AccountMenu;
