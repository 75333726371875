import React from 'react';

import { connect } from 'react-redux';
import { showPopup, flyTo } from '../actions';

import ToolBar from '../components/ToolBar';
import { PointOfInterest } from '../betterTypes';

interface ToolBarContainerProps {
  pointOfInterests: Array<PointOfInterest>;
  showPopup: (poi: PointOfInterest) => void;
  flyTo: (poi: PointOfInterest) => void;
}

function ToolBarContainer({ pointOfInterests, showPopup, flyTo }: ToolBarContainerProps) {
  const handleSearchRequest = (poi: PointOfInterest) => {
    showPopup(poi);
    flyTo(poi);
  };

  return <ToolBar pointOfInterests={pointOfInterests} search={handleSearchRequest} />;
}

export default connect(
  ({ routes }: { routes: ToolBarContainerProps }) => ({
    pointOfInterests: routes.pointOfInterests,
  }),
  {
    showPopup,
    flyTo,
  },
)(ToolBarContainer);
