import React from 'react';
import { FloatingActionButton, FontIcon, Chip } from 'material-ui';

import { useTranslation } from 'react-i18next';

import { Route } from '../betterTypes';

interface FloatingMenuProps {
  height: number;
  width: number;
  hasPaid: boolean;
  route: Route;
  handleAccountTap: (a: boolean) => void;
  handleRouteTap: (a: boolean) => void;
  handleClearRoute: () => void;
}

function FloatingMenu({
  width,
  height,
  hasPaid,
  route,
  handleAccountTap,
  handleRouteTap,
  handleClearRoute,
}: FloatingMenuProps) {
  const { i18n, t } = useTranslation();

  return (
    <div>
      <FloatingActionButton
        mini
        style={{
          position: 'absolute',
          top: height - 190,
          left: width - 60,
          zIndex: 9999,
        }}
        onMouseUp={() => handleAccountTap(true)}
      >
        <FontIcon className="material-icons">account_circle</FontIcon>
      </FloatingActionButton>
      {!hasPaid ? (
        <div>
          <div>
            <FloatingActionButton
              mini
              disabled
              style={{
                position: 'absolute',
                top: height - 130,
                left: width - 60,
                zIndex: 9999,
              }}
              onMouseUp={() => handleRouteTap(true)}
            >
              <FontIcon className="material-icons">directions_boat</FontIcon>
            </FloatingActionButton>
          </div>
          {!hasPaid && (
            <div>
              <Chip
                backgroundColor="#81C784"
                style={{
                  position: 'absolute',
                  top: height - 125,
                  left: width - (i18n.language === 'en' ? 160 : 150),
                  zIndex: 9999,
                  fontSize: 10,
                }}
                onRequestDelete={() => handleAccountTap(true)}
              >
                {t('activate')}
              </Chip>
            </div>
          )}
        </div>
      ) : (
        <FloatingActionButton
          mini
          secondary
          style={{
            position: 'absolute',
            top: height - 130,
            left: width - 60,
            zIndex: 9999,
          }}
          onMouseUp={() => handleRouteTap(true)}
        >
          <FontIcon className="material-icons">directions_boat</FontIcon>
        </FloatingActionButton>
      )}
      {route.length < 1 ? (
        <FloatingActionButton
          mini
          disabled
          style={{
            position: 'absolute',
            top: height - 70,
            left: width - 60,
            zIndex: 9999,
          }}
          onMouseUp={() => handleClearRoute()}
        >
          <FontIcon className="material-icons">clear</FontIcon>
        </FloatingActionButton>
      ) : (
        <FloatingActionButton
          mini
          secondary
          style={{
            position: 'absolute',
            top: height - 70,
            left: width - 60,
            zIndex: 9999,
          }}
          onMouseUp={() => handleClearRoute()}
        >
          <FontIcon className="material-icons">clear</FontIcon>
        </FloatingActionButton>
      )}
    </div>
  );
}

export default FloatingMenu;
