import { combineReducers } from 'redux';
import { encrypt } from './utils/userInfoUtils';

export const moveInArray = (array, oldIndex, newIndex) => {
  const theNewIndex = newIndex >= array.length ? array.length - 1 : newIndex;
  const item = array[oldIndex];
  const oldItemRemoved = [...array.slice(0, oldIndex), ...array.slice(oldIndex + 1)];
  const newArray = [...oldItemRemoved.slice(0, theNewIndex), item, ...oldItemRemoved.slice(theNewIndex)];
  return newArray;
};

const routesInitialState = {
  storedRoutes: [],
  route: [],
  useAutomaticRoute: true,
  pointOfInterests: [],
  waypoints: [],
  cities: {
    Helsinki: { lat: 60.1641013, lon: 24.9001866 },
  },
  selected: 'Helsinki',
  showLoginForm: false,
  showRegisterForm: false,
  showAccountMenu: false,
  showFlashScreen: true,
  showRouteMenu: false,
  redraw: false,
  scoutRoutesActive: false,
  scoutRoutes: [],
};

// eslint-disable-next-line default-param-last
const routes = (state = routesInitialState, action) => {
  switch (action.type) {
    case 'LOCATION_RECEIVED':
      return { ...state, position: action.position };
    case 'ROUTE_ERROR_RECEIVED':
      return { ...state, routeError: action.error };
    case 'ADD_TO_ROUTE':
      return {
        ...state,
        poi: action.poi,
        route: state.isCalculatingRoute ? state.route : state.route.concat([action.poi]),
        redraw: true,
        isCalculatingRoute: state.isCalculatingRoute ? true : state.route.length > 1,
      };
    case 'SET_ROUTE':
      return {
        ...state,
        route: action.route,
        redraw: false,
      };
    case 'REMOVE_FROM_ROUTE':
      return {
        ...state,
        route: state.route.filter((poi) => action.poi.properties.id !== poi.properties.id),
        redraw: true,
      };
    case 'MOVE_FORWARD_ON_ROUTE':
      return { ...state, route: moveInArray(state.route, action.index, action.index + 1) };
    case 'ROUTE_SAVED_RECEIVED':
      return { ...state, routeName: action.route.name };
    case 'MOVE_BACK_ON_ROUTE':
      return { ...state, route: moveInArray(state.route, action.index, action.index - 1) };
    case 'CLEAR_ROUTE':
      if (state.isCalculatingRoute) return state;

      return {
        ...state,
        route: [],
        routeName: '',
        redraw: true,
        isCalculatingRoute: false,
      };
    case 'STORED_ROUTES_RECEIVED':
      return { ...state, storedRoutes: action.routes };
    case 'TOGGLE_ROUTE':
      return { ...state, showRouteMenu: action.show };
    case 'TOGGLE_AUTOMATIC_ROUTE':
      return { ...state, useAutomaticRoute: action.use };
    case 'POIS_RECEIVED':
      return { ...state, pointOfInterests: action.pois };
    case 'WAYPOINTS_RECEIVED':
      return { ...state, waypoints: action.waypoints };
    case 'CLEAR_WAYPOINTS':
      return {
        ...state,
        waypoints: [],
      };
    case 'FLY_TO':
      return { ...state, flyToPOI: action.poi };
    case 'INVALIDATE':
      return {
        ...state,
        storedRoutes: [],
        route: [],
        poi: {},
        waypoints: [],
      };
    case 'SET_CALCULATING_ROUTE':
      return { ...state, isCalculatingRoute: action.isCalculatingRoute };
    case 'SET_SCOUT_ROUTES_ACTIVE':
      return { ...state, scoutRoutesActive: action.show };
    case 'SCOUT_ROUTES_RECEIVED':
      return { ...state, scoutRoutes: action.routes };
    default:
      return state;
  }
};

const initialUserState = {
  showLoginForm: true,
  showRegisterForm: false,
  showAccountMenu: false,
  user: {},
  userInfo: '',
  forgotPasswordFormLoading: false,
};

// eslint-disable-next-line default-param-last
const user = (state = initialUserState, action) => {
  switch (action.type) {
    case 'LOGIN_RECEIVED':
      return {
        ...state,
        user: action.user,
        showLoginForm: false,
        showRegisterForm: false,
        showAccountMenu: false,
      };
    case 'USER_INFO_RECEIVED':
      return { ...state, userInfo: encrypt(action.user).toString() };
    case 'TOGGLE_ACCOUNT':
      return { ...state, showAccountMenu: action.show };
    case 'TOGGLE_LOGIN':
      return { ...state, showLoginForm: action.show };
    case 'TOGGLE_REGISTER':
      return { ...state, showRegisterForm: action.show };
    case 'TOGGLE_FORGOT_PASSWORD':
      return {
        ...state,
        showForgotPasswordForm: action.show,
        showLoginForm: !action.show,
        showAccountMenu: false,
      };
    case 'SET_FORGOT_LOADING':
      return { ...state, forgotPasswordFormLoading: action.isLoading };
    case 'INVALIDATE':
      return {
        ...state,
        user: action.user,
        userInfo: action.userInfo,
        showLoginForm: true,
        showRegisterForm: false,
        showForgotPasswordForm: false,
      };
    case 'SET_SEEN':
      return { ...state, seen: action.seen };
    default:
      return state;
  }
};

const initialMessagesState = {
  showSnackbar: false,
  snackbarMessage: '',
};

// eslint-disable-next-line default-param-last
const messages = (state = initialMessagesState, action) => {
  switch (action.type) {
    case 'SHOW_MESSAGE':
      return {
        ...state,
        showSnackbar: true,
        snackbarMessage: action.message,
      };
    case 'HIDE_MESSAGE':
      return {
        ...state,
        showSnackbar: false,
        snackbarMessage: '',
      };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
const flashScreen = (state = { showFlashScreen: false }, action) => {
  switch (action.type) {
    case 'HIDE_FLASH_SCREEN':
      return { ...state, showFlashScreen: false };
    case 'CLEAR_WAYPOINTS':
      return { ...state, showFlashScreen: false };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
const popUp = (state = {}, action) => {
  switch (action.type) {
    case 'SHOW_POPUP':
      return { ...state, poi: action.poi };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
const tips = (state = { seenInfo: false, infoPage: undefined }, action) => {
  switch (action.type) {
    case 'SET_SEEN':
      return { ...state, seenInfo: action.seen, infoPage: undefined };
    case 'SET_INFO_PAGE':
      return { ...state, infoPage: action.infoPage };
    default:
      return state;
  }
};

// eslint-disable-next-line default-param-last
const spinner = (state = { showSpinner: false }, action) => {
  switch (action.type) {
    case 'SET_SPINNER':
      return { ...state, showSpinner: action.show };
    case 'LOGIN_RECEIVED':
      return { ...state, showSpinner: false };
    case 'WAYPOINTS_RECEIVED':
      return { ...state, showSpinner: false };
    case 'USER_INFO_RECEIVED':
      return { ...state, showSpinner: false };
    case 'ROUTE_ERROR_RECEIVED':
      return { ...state, showSpinner: false };
    case 'SHOW_MESSAGE':
      return { ...state, showSpinner: false };
    default:
      return state;
  }
};

export default combineReducers({
  routes,
  user,
  messages,
  flashScreen,
  popUp,
  tips,
  spinner,
});
