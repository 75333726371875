import PropTypes from 'prop-types';

const layoutType = PropTypes.arrayOf(
  PropTypes.shape({
    i: PropTypes.string.isRequired,
    x: PropTypes.number.isRequired,
    y: PropTypes.number.isRequired,
    w: PropTypes.number.isRequired,
    h: PropTypes.number.isRequired,
    static: PropTypes.bool.isRequired,
  }),
);

export const scoutRoute = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  user_id: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  point_of_interests: PropTypes.arrayOf(PropTypes.number),
  waypoints: PropTypes.arrayOf(
    PropTypes.shape({
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      order_id: PropTypes.number,
      userpoint: PropTypes.bool,
      fairway: PropTypes.bool,
    }),
  ),
});

export default {
  user: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number.isRequired,
      first_name: PropTypes.string.isRequired,
      last_name: PropTypes.string.isRequired,
    }),
    userInfo: PropTypes.string,
  }),
  poi: PropTypes.shape({
    type: PropTypes.string,
    geometry: PropTypes.shape({
      type: PropTypes.string,
      coordinates: PropTypes.arrayOf(PropTypes.number),
    }),
    properties: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      'marker-symbol': PropTypes.string,
      lat: PropTypes.number,
      lon: PropTypes.number,
    }),
  }),
  route: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  waypoint: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
  }),
  layout: layoutType,
  layouts: PropTypes.shape({
    sm: layoutType.isRequired,
    md: layoutType.isRequired,
    lg: layoutType.isRequired,
  }),
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lon: PropTypes.number.isRequired,
  }),
  position: PropTypes.shape({
    latitude: PropTypes.number,
    longitude: PropTypes.number,
  }),
  scoutRoutes: PropTypes.arrayOf(scoutRoute),
};
