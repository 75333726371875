import React, { useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import { List, ListItem } from 'material-ui/List';
import TextField from 'material-ui/TextField';
import RaisedButton from 'material-ui/RaisedButton';
import FlatButton from 'material-ui/FlatButton';

import { useTranslation } from 'react-i18next';

interface ResetPasswordFormProps {
  handleChangePassword: (password: string, token: string | null) => Promise<boolean>;
  handleShowMessage: (message: string) => void;
}

function ResetPasswordForm({ handleChangePassword, handleShowMessage }: ResetPasswordFormProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');

  const cancel = () => {
    history.push('/');
  };
  const changePassword = async () => {
    if (password !== repeatPassword) {
      handleShowMessage(t('forgot.passwords-dont-match'));
      return;
    }

    const params = new URLSearchParams(location.search);
    const token = params.get('reset_password_token');
    const passwordChanged = await handleChangePassword(password, token);
    if (passwordChanged) {
      history.push('/');
    }
  };
  return (
    <div>
      <List>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="top-rounded">
          <TextField
            hintText={t('forgot.password')}
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword((e.target as HTMLInputElement).value)}
          />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="top-rounded">
          <TextField
            hintText={t('forgot.repeat-password')}
            type="password"
            fullWidth
            value={repeatPassword}
            onChange={(e) => setRepeatPassword((e.target as HTMLInputElement).value)}
          />
        </ListItem>
        <ListItem disabled style={{ backgroundColor: '#ffffff' }} className="bottom-rounded">
          <RaisedButton label={t('forgot.request-password-change')} primary onMouseUp={changePassword} />
          <FlatButton label={t('forgot.cancel')} secondary onMouseUp={cancel} />
        </ListItem>
      </List>
    </div>
  );
}

export default ResetPasswordForm;
