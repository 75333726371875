import React from 'react';

import { useLocation } from 'react-router-dom';

import useWindowsSize from '@rehooks/window-size';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { setSeen, setInfoPage } from '../actions';

import Tips from '../components/Tips';

interface TipsType {
  seenInfo: boolean;
  infoPage: number;
}

interface TipsContainerProps {
  tips: TipsType;
  setSeen: (seen: boolean) => void;
  setInfoPage: () => void;
}

function TipsContainer({ tips, setSeen, setInfoPage }: TipsContainerProps) {
  const location = useLocation();

  if (location.pathname === 'checkout') return null;
  if (tips.seenInfo) return null;

  const { innerWidth, innerHeight } = useWindowsSize();

  return (
    <Tips
      handleSeenInfo={() => setSeen(true)}
      handleInfoPageChange={setInfoPage}
      infoPage={tips.infoPage}
      width={innerWidth}
      height={innerHeight}
    />
  );
}

export default compose(connect(({ tips }: TipsContainerProps) => ({ tips }), { setSeen, setInfoPage }))(TipsContainer);
