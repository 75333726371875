import React from 'react';

import useWindowsSize from '@rehooks/window-size';

import { connect } from 'react-redux';
import { toggleAutomaticRoute } from '../actions';

import AutomaticRouteToggle from '../components/AutomaticRouteToggle';

interface AutomaticRouteToggleContainerProps {
  routes: {
    useAutomaticRoute: boolean;
  };
  toggleAutomaticRoute: () => void;
}

function AutomaticRouteToggleContainer({ routes, toggleAutomaticRoute }: AutomaticRouteToggleContainerProps) {
  const { innerHeight } = useWindowsSize();

  return (
    <AutomaticRouteToggle
      height={innerHeight}
      useAutomaticRoute={routes.useAutomaticRoute}
      handleToggleUseAutomaticRoute={toggleAutomaticRoute}
    />
  );
}

export default connect(({ routes }: { routes: { useAutomaticRoute: boolean } }) => ({ routes }), {
  toggleAutomaticRoute,
})(AutomaticRouteToggleContainer);
