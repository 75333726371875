import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { compose } from 'redux';
import { connect } from 'react-redux';
import {
  invalidate,
  fetchUserInfo,
  fetchStoredRoutes,
  fetchStoredRoute,
  setSeen,
  toggleAccount,
  toggleRoute,
  toggleScoutRoutes,
} from '../actions';

import AccountMenu from '../components/AccountMenu';

import toggleLanguage from '../utils/languageUtils';

function AccountMenuContainer({
  showAccountMenu,
  toggleAccount,
  toggleRoute,
  fetchUserInfo,
  fetchStoredRoutes,
  invalidate,
  setSeen,
  user,
  userInfo,
  toggleScoutRoutes,
  scoutRoutesActive,
}) {
  const { i18n } = useTranslation();
  const tap = (show) => {
    toggleAccount(show);
    toggleRoute(false);
    if (show) {
      fetchStoredRoutes(user);
    }
  };

  const handleShowInfo = () => {
    tap(false);
    setSeen(false);
  };

  const handleLogout = () => invalidate();

  const handleLanguageToggle = () => {
    toggleLanguage(i18n);
  };

  const handleFetchUserInfo = () => {
    fetchUserInfo(user);
  };

  return (
    <AccountMenu
      tap={tap}
      userInfo={userInfo}
      showAccountMenu={showAccountMenu}
      handleShowInfo={handleShowInfo}
      handleLogout={handleLogout}
      handleLanguageToggle={handleLanguageToggle}
      handleScoutToggle={toggleScoutRoutes}
      scoutRoutesActive={scoutRoutesActive}
      handleFetchUserInfo={handleFetchUserInfo}
    />
  );
}

AccountMenuContainer.defaultProps = {
  user: null,
  userInfo: null,
};

AccountMenuContainer.propTypes = {
  showAccountMenu: PropTypes.bool.isRequired,
  toggleAccount: PropTypes.func.isRequired,
  toggleRoute: PropTypes.func.isRequired,
  fetchUserInfo: PropTypes.func.isRequired,
  fetchStoredRoutes: PropTypes.func.isRequired,
  invalidate: PropTypes.func.isRequired,
  setSeen: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number.isRequired,
    email: PropTypes.string.isRequired,
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    paid_until: PropTypes.string,
    scout_paid_until: PropTypes.string,
    token: PropTypes.string.isRequired,
  }),
  userInfo: PropTypes.string,
  toggleScoutRoutes: PropTypes.func.isRequired,
  scoutRoutesActive: PropTypes.bool.isRequired,
};

export default compose(
  connect(
    ({ user, routes }) => ({
      showAccountMenu: user.showAccountMenu,
      user: user.user,
      userInfo: user.userInfo,
      scoutRoutesActive: routes.scoutRoutesActive,
    }),
    {
      invalidate,
      setSeen,
      fetchStoredRoutes,
      fetchStoredRoute,
      fetchUserInfo,
      toggleAccount,
      toggleRoute,
      toggleScoutRoutes,
    },
  ),
)(AccountMenuContainer);
