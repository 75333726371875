import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import {
  getRoute,
  addToRoute,
  flyTo,
  getPois,
  removeFromRoute,
  toggleAccount,
  toggleRoute,
  showPopup,
  setSpinner,
} from '../actions';

import Types from '../types';

import Map from '../components/Map';

function MapContainer({
  mapCenter,
  position,
  pointOfInterests,
  waypoints,
  route,
  useAutomaticRoute,
  getPois,
  flyTo,
  flyToPOI,
  addToRoute,
  removeFromRoute,
  toggleAccount,
  toggleRoute,
  showPopup,
  width,
  height,
  setSpinner,
  getRoute,
  redraw,
  scoutRoutesActive,
  scoutRoutes,
}) {
  const handleDrawLine = (poi) => {
    const directProperties = poi.properties;
    directProperties.directRoute = true;
    const directPoi = poi;
    directPoi.properties = directProperties;
    addToRoute(directPoi);
  };

  const handleMenuClose = () => {
    toggleAccount(false);
    toggleRoute(false);
  };

  return (
    <Map
      center={mapCenter}
      position={position}
      pointOfInterests={pointOfInterests}
      waypoints={waypoints}
      route={route}
      flyTo={flyToPOI}
      flyToPoint={flyTo}
      poiTap={showPopup}
      handleAddToRoute={addToRoute}
      handleDrawLineRoute={handleDrawLine}
      handleGetPois={getPois}
      handleRemoveFromRoute={removeFromRoute}
      closeMenus={handleMenuClose}
      useAutomaticRoute={useAutomaticRoute}
      width={width}
      height={height}
      setSpinner={setSpinner}
      getRoute={getRoute}
      redraw={redraw}
      scoutRoutesActive={scoutRoutesActive}
      scoutRoutes={scoutRoutes}
    />
  );
}

MapContainer.defaultProps = {
  position: undefined,
  flyToPOI: null,
};

MapContainer.propTypes = {
  mapCenter: Types.center.isRequired,
  position: Types.position,
  pointOfInterests: PropTypes.arrayOf(Types.poi).isRequired,
  waypoints: PropTypes.arrayOf(Types.waypoint).isRequired,
  route: Types.route.isRequired,
  useAutomaticRoute: PropTypes.bool.isRequired,
  getPois: PropTypes.func.isRequired,
  getRoute: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  setSpinner: PropTypes.func.isRequired,
  redraw: PropTypes.bool.isRequired,
  flyToPOI: Types.poi,
  addToRoute: PropTypes.func.isRequired,
  removeFromRoute: PropTypes.func.isRequired,
  toggleAccount: PropTypes.func.isRequired,
  toggleRoute: PropTypes.func.isRequired,
  showPopup: PropTypes.func.isRequired,
  flyTo: PropTypes.func.isRequired,
  scoutRoutesActive: PropTypes.bool.isRequired,
  scoutRoutes: Types.scoutRoutes.isRequired,
};

export default connect(
  ({ routes }) => ({
    mapCenter: routes.cities[routes.selected],
    position: routes.position,
    pointOfInterests: routes.pointOfInterests,
    waypoints: routes.waypoints,
    route: routes.route,
    useAutomaticRoute: routes.useAutomaticRoute,
    flyToPOI: routes.flyToPOI,
    redraw: routes.redraw,
    scoutRoutesActive: routes.scoutRoutesActive,
    scoutRoutes: routes.scoutRoutes,
  }),
  {
    getRoute,
    addToRoute,
    flyTo,
    getPois,
    removeFromRoute,
    toggleAccount,
    toggleRoute,
    showPopup,
    setSpinner,
  },
)(MapContainer);
