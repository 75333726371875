import React from 'react';

import { useLocation } from 'react-router-dom';

import { compose } from 'redux';
import { connect } from 'react-redux';
import { Layouts } from 'react-grid-layout';

import ScoutCheckout from '../components/ScoutCheckout';

import { paidUntil, hasPaid } from '../utils/userInfoUtils';

interface ScoutCheckoutContainerProps {
  userInfo: string;
  layouts: Layouts;
}

function ScoutCheckoutContainer({ layouts, userInfo }: ScoutCheckoutContainerProps) {
  const location = useLocation();

  if (location.pathname === 'scout-checkout') return null;

  return <ScoutCheckout layouts={layouts} paidUntil={paidUntil(userInfo)} hasPaid={hasPaid(userInfo)} />;
}

export default compose(connect(({ user }: { user: { userInfo: string } }) => ({ userInfo: user.userInfo })))(
  ScoutCheckoutContainer,
);
