import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import bearing from '@turf/bearing';
import distanceFunc from '@turf/distance';
import { lineString } from '@turf/helpers';
import simplify from '@turf/simplify';
import {
  MenuItem,
  Card,
  CardHeader,
  CardText,
  DropDownMenu,
  Drawer,
  TextField,
  FlatButton,
  Divider,
  FloatingActionButton,
} from 'material-ui';
import { grey50, blue500, grey800 } from 'material-ui/styles/colors';
import FontIcon from 'material-ui/FontIcon';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import Types from '../types';

// import { hasChanged } from '../utils/propsUtils';

import ExportRoute from './ExportRoute';

const styles = {
  leftnav: {
    backgroundColor: grey50,
  },
  menuitem: {
    color: '#ffffff',
    backgroundColor: blue500,
    fontSize: 12,
  },
  menuitemBlurry: {
    backgroundColor: blue500,
    fontSize: 12,
    color: 'transparent',
    textShadow: '0 0 2px rgba(255,255,255,0.5)',
  },
  menuitemSmall: {
    color: '#ffffff',
    backgroundColor: blue500,
    fontSize: 8,
  },
  menuitemNormal: {
    color: grey800,
    backgroundColor: grey50,
    fontSize: 12,
    marginLeft: -10,
  },
  menuitemAction: {
    color: grey800,
    border: 0,
    fontSize: 12,
    marginLeft: -10,
  },
  menuitemGenerated: {
    color: grey800,
    backgroundColor: grey50,
    border: 0,
    fontSize: 12,
    marginLeft: -10,
  },
  icon: {
    width: '14px',
    height: '14px',
  },
  button: {
    width: '90%',
    margin: '5%',
  },
  scoutRoutesBlock: {
    padding: '5%',
  },
};

const fixLatitude = (wp) => {
  if (!wp.lat && wp.latitude) {
    return wp.latitude;
  }

  if (!wp.lat && !wp.latitude) {
    return wp[1];
  }

  return wp.lat;
};

const fixLongitude = (wp) => {
  if (!wp.lon && wp.longitude) return wp.longitude;
  if (!wp.lon && !wp.longitude) return wp[0];
  return wp.lon;
};

const createCoordinatesFromWaypoints = (waypoints) => {
  if (!waypoints) return [];
  return waypoints.map((wp) => [fixLongitude(wp), fixLatitude(wp)]);
};

const createFeatureFromWaypoints = (waypoints) => {
  const coordinates = createCoordinatesFromWaypoints(waypoints);

  return lineString(coordinates);
};

function RouteMenu({
  removeFromRoute,
  flyTo,
  moveForwardOnRoute,
  moveBackOnRoute,
  handleSaveRoute,
  handleDeleteRoute,
  tap,
  hasPaid,
  storedRoutes,
  handleFetchRouteRequest,
  handleExportRoute,
  waypoints,
  clearRoute,
  width,
  height,
  // poi,
  showRouteMenu,
  route,
}) {
  const [show, setShow] = useState(false);
  const [routeName, setRouteName] = useState('');
  const [speed, setSpeed] = useState(10);
  const [selectedRoute, setSelectedRoute] = useState(-1);

  const { t } = useTranslation();

  useEffect(() => {
    if (showRouteMenu) {
      // const title = (poi && poi.properties) ? poi.properties.title : '';
      setShow(true);
    } else {
      setShow(false);
    }
  }, [route, showRouteMenu]);

  const toNauticalMiles = (kilometers) => kilometers * 0.539956803;

  const calculateOverallDistance = (waypoints) => {
    if (!waypoints) return null;
    const distances = waypoints.map((wp, index) => {
      if (index + 1 < waypoints.length) {
        return distanceFunc(
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [fixLongitude(wp), fixLatitude(wp)],
            },
          },
          {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [fixLongitude(waypoints[index + 1]), fixLatitude(waypoints[index + 1])],
            },
          },
        );
      }
      return 0;
    });

    const kilometers = distances.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
    return toNauticalMiles(kilometers).toFixed(1);
  };

  const simplifiedWaypoints = (waypoints) =>
    simplify(createFeatureFromWaypoints(waypoints), { tolerance: 0, highQuality: true }).geometry.coordinates;

  const handleRouteNameChange = (event) => {
    setRouteName(event.target.value);
  };

  const handleSelectedRouteChange = (id) => {
    setSelectedRoute(id);
  };

  const handleSpeedChange = (event) => {
    let speed = parseFloat(event.target.value);
    if (!_.isFinite(speed)) {
      speed = 0;
    }
    setSpeed(speed);
  };

  const calculateBearing = (waypoints, index) => {
    const wp = waypoints[index];
    let totalBearing = 0;

    if (wp && index + 1 < waypoints.length) {
      totalBearing = bearing(
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [fixLongitude(wp), fixLatitude(wp)],
          },
        },
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [fixLongitude(waypoints[index + 1]), fixLatitude(waypoints[index + 1])],
          },
        },
      );
    }
    if (totalBearing < 0) totalBearing += 360;
    return `${_.padStart(totalBearing.toFixed(0), 3, '0')} \xB0`;
  };

  const calculateDuration = (speed, distance) => {
    if (!speed || !distance) {
      return '';
    }

    const durationInMinutes = (distance / speed) * 60;
    const hours = durationInMinutes / 60;
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);

    return t('routes.travel-duration', { hours: rhours, minutes: rminutes });
  };

  const calculateDistance = (waypoints, index) => {
    const wp = waypoints[index];
    let totalDistance = 0;

    if (wp && index - 1 >= 0) {
      totalDistance = distanceFunc(
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [fixLongitude(wp), fixLatitude(wp)],
          },
        },
        {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'Point',
            coordinates: [fixLongitude(waypoints[index - 1]), fixLatitude(waypoints[index - 1])],
          },
        },
      );
    }
    return toNauticalMiles(totalDistance).toFixed(1);
  };

  let currentStoredRoute = routeName ? storedRoutes.filter((r) => r.name === routeName)[0] : { id: -1 };
  if (!currentStoredRoute) {
    currentStoredRoute = { id: -1 };
  }

  const hasRouteWithSameName = (name) => storedRoutes.filter((r) => r.name === name).length > 0;

  const simplified = waypoints.length > 0 ? simplifiedWaypoints(waypoints) : [];

  const distance = calculateOverallDistance(waypoints);

  return (
    <>
      <Drawer open={show} style={styles.leftnav} openSecondary>
        <MenuItem
          style={{ backgroundColor: '#ECEFF1' }}
          onClick={() => tap(false)}
          leftIcon={<FontIcon className="material-icons">chevron_right</FontIcon>}
        >
          <span
            style={{
              fontFamily: 'Lato',
              color: '#CFD8DC',
              marginTop: 20,
            }}
          >
            SAILMATE
          </span>
          <span style={{ color: '#42A5F5', marginLeft: 20, fontSize: 12 }} className="icon-sails" />
        </MenuItem>
        <Divider />
        {routeName !== '' && (
          <div>
            <MenuItem
              disabled
              primaryText={`${routeName || 'Reitin nimi'}`}
              style={{
                fontSize: '14px',
                marginLeft: '-10px',
                color: 'black',
                fontWeight: 'bold',
              }}
            />
            <Divider />
          </div>
        )}
        <TextField
          floatingLabelText={t('routes.speed')}
          value={speed}
          style={styles.button}
          onChange={handleSpeedChange}
          disabled={route.length === 0 || !hasPaid}
        />
        <MenuItem
          disabled
          style={{ fontSize: '12px', marginTop: '-25px' }}
          primaryText={`(${_.round(speed * 1.852)} km/h)`}
        />
        <MenuItem
          disabled
          primaryText={`${t('routes.length')}: ${distance} ${t('units.nautical-mile.short')}
            (${_.round(distance * 1.852, 1)} km)`}
          style={{ fontSize: '12px' }}
        />
        <MenuItem
          disabled
          primaryText={`${t('routes.duration')}: ${calculateDuration(speed, distance)}`}
          style={{ fontSize: '12px' }}
        />
        <Divider />
        <Card style={styles.menuitemAction} disabled={!hasPaid}>
          <CardHeader title={t('routes.my-routes')} titleColor="grey" actAsExpander showExpandableButton />
          <CardText expandable>
            <DropDownMenu
              maxHeight={300}
              value={selectedRoute}
              onChange={(event, index, value) => handleSelectedRouteChange(value)}
              style={{ width: '100%' }}
            >
              {[{ id: -1, name: t('routes.select') }].concat(storedRoutes).map((storedRoute) => (
                <MenuItem value={storedRoute.id} key={storedRoute.id} primaryText={storedRoute.name} />
              ))}
            </DropDownMenu>
            <FlatButton
              label={t('routes.open')}
              style={styles.button}
              disabled={selectedRoute === -1}
              onMouseUp={() => {
                if (selectedRoute === -1) {
                  return 'ignore';
                }
                return handleFetchRouteRequest(selectedRoute);
              }}
            />
            <FlatButton
              label={t('routes.remove')}
              style={styles.button}
              disabled={selectedRoute === -1}
              onMouseUp={() => {
                handleDeleteRoute(selectedRoute);
                handleSelectedRouteChange(-1);
              }}
            />
            <ExportRoute
              selectedRoute={selectedRoute}
              storedRoutes={storedRoutes}
              handleExportRoute={handleExportRoute}
            />
          </CardText>
        </Card>
        <Card style={styles.menuitemAction} disabled={!hasPaid}>
          <CardHeader title={t('routes.save')} titleColor="grey" actAsExpander showExpandableButton />
          <CardText expandable>
            <TextField
              hintText={t('routes.name')}
              value={routeName}
              style={styles.button}
              onChange={handleRouteNameChange}
              disabled={route.length === 0 || !hasPaid}
            />
            <FlatButton
              label={t('routes.save-action')}
              style={styles.button}
              disabled={!routeName || hasRouteWithSameName(routeName) || route.length === 0 || !hasPaid}
              onMouseUp={() => handleSaveRoute(routeName)}
            />
          </CardText>
        </Card>
        <Divider />
        <Divider />
        {route.map((feature, i) => {
          const { title, id } = feature.properties;
          const poi = feature.properties.type === 'marker';
          return (
            <MenuItem
              key={id}
              menuItems={[
                <MenuItem primaryText={t('routes.remove-from-route')} onClick={() => removeFromRoute(feature)} />,
                <MenuItem
                  disabled={i === route.length - 1}
                  primaryText={t('routes.move-forward')}
                  onClick={() => moveForwardOnRoute(i)}
                />,
                <MenuItem disabled={i === 0} primaryText={t('routes.move-back')} onClick={() => moveBackOnRoute(i)} />,
              ]}
              style={styles.menuitemNormal}
              primaryText={title}
              leftIcon={
                poi ? (
                  <FontIcon className="material-icons">pin_drop</FontIcon>
                ) : (
                  <FontIcon className="material-icons">person_pin_circle</FontIcon>
                )
              }
              rightIcon={<FontIcon className="material-icons">more_vert</FontIcon>}
            />
          );
        })}
        <Divider />
        {waypoints && waypoints.length > 0 && (
          <Card style={styles.menuitemGenerated}>
            <CardHeader
              title={t('routes.automatic-waypoints')}
              titleColor="grey"
              actAsExpander
              showExpandableButton
              titleStyle={{ fontSize: '12px' }}
            />
            <CardText expandable>
              {simplified.map((wp, i) => (
                <MenuItem
                  key={wp.concat(i)}
                  style={styles.menuitemGenerated}
                  primaryText={`${wp[1].toFixed(3)}, ${wp[0].toFixed(3)}
                  ${calculateDistance(simplified, i)}
                  ${t('units.nautical-mile.short')},
                  ${calculateBearing(simplified, i)} `.replace(/\n/g, '')}
                  leftIcon={<FontIcon className="material-icons">place</FontIcon>}
                  onMouseUp={() =>
                    flyTo({
                      properties: { lon: wp[0], lat: wp[1] },
                    })
                  }
                />
              ))}
            </CardText>
          </Card>
        )}
      </Drawer>
      {show && (
        <FloatingActionButton
          mini
          secondary={waypoints.length > 0}
          disabled={waypoints.length === 0}
          style={{
            position: 'absolute',
            top: height - 70,
            left: width - 310,
            zIndex: 9999,
          }}
          onMouseUp={clearRoute}
        >
          <FontIcon className="material-icons">clear</FontIcon>
        </FloatingActionButton>
      )}
    </>
  );
}

RouteMenu.defaultProps = {
  hasPaid: false,
  storedRoutes: [],
};

RouteMenu.propTypes = {
  removeFromRoute: PropTypes.func.isRequired,
  flyTo: PropTypes.func.isRequired,
  moveForwardOnRoute: PropTypes.func.isRequired,
  moveBackOnRoute: PropTypes.func.isRequired,
  handleSaveRoute: PropTypes.func.isRequired,
  handleDeleteRoute: PropTypes.func.isRequired,
  tap: PropTypes.func.isRequired,
  /* eslint-disable react/no-unused-prop-types */
  showRouteMenu: PropTypes.bool.isRequired,
  /* eslint-enable react/no-unused-prop-types */
  hasPaid: PropTypes.bool,
  storedRoutes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      user_id: PropTypes.number.isRequired,
      name: PropTypes.string,
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string,
    }),
  ),
  handleFetchRouteRequest: PropTypes.func.isRequired,
  handleExportRoute: PropTypes.func.isRequired,
  waypoints: PropTypes.arrayOf(Types.waypoint).isRequired,
  clearRoute: PropTypes.func.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  route: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // poi: PropTypes.object.isRequired,
};

export default RouteMenu;
